import './css/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
// 增加的引入
import 'element-plus/dist/index.css' 
//element-plus使用中文提示词
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import katex from 'katex'
// // !import 不能忘记引入katex的样式
import 'katex/dist/katex.css'
// 引入katex下的自动渲染函数
import renderMathInElement from 'katex/contrib/auto-render/auto-render'
import floatMove from '@/utils/floatMove'

//pptcss
import "prosemirror-gapcursor/style/gapcursor.css";
import '@icon-park/vue-next/styles/index.css'
import 'prosemirror-view/style/prosemirror.css'
import 'animate.css'
import '@/assets/styles/prosemirror.scss'
import '@/assets/styles/global.scss'
import '@/assets/styles/font.scss'
import Icon from '@/plugins/icon'
import Directive from '@/plugins/directive'

import Vant from 'vant'
import 'vant/lib/index.css';

const app = createApp(App)
// 定义自动渲染的配置参数
const renderOption = {
  delimiters: [
    {left: '$$', right: '$$', display: true},
    {left: '$', right: '$', display: false},
    {left: '\\(', right: '\\)', display: false},
    {left: '\\[', right: '\\]', display: true}
  ],
    throwOnError: false,
    maxSize:100,
}
function renderMath(dom: any) {
  if (dom) {
      renderMathInElement(dom, renderOption)
  }    
}

app.config.globalProperties.$renderMath = renderMath;

app.use(createPinia())
app.use(Icon)
app.use(Directive)
app.use(router)
app.use(ElementPlus, { locale: zhCn })
app.use(floatMove)
app.use(Vant)
app.mount('#app')
