import { ElMessage } from 'element-plus'
export class ElementTip {
  static successTip(str: string, duration: number = 2000) {
    ElMessage({
      message: str,
      type: 'success',
      duration,
      grouping: true
    })
  }
  static errorTip(str: string, duration: number = 2000) {
    ElMessage({
      message: str,
      type: 'error',
      duration,
      grouping: true
    })
  }
  static warningTip(str: string, duration: number = 2000) {
    ElMessage({
      message: str,
      type: 'warning',
      duration,
      grouping: true
    })
  }
}
