import { reactive, ref } from 'vue'
import type { basicSetType, compositionType, imgDataType, userInfo } from '@/stores/TypeIntertface'
import { ElementTip } from '@/stores/ElementTip'
export class Data {
  static resourceType = ref<any>([
    {
      id: 'answers',
      dictName: '小鸿问答',
      imgUrl: 'icons/xiaohong-icon.png',
      hoverImgUrl: 'icons/xiaohong-icon-hover.png'
    },
    {
      id: 'application',
      dictName: '教育应用',
      imgUrl: 'icons/app-icon.png',
      hoverImgUrl: 'icons/app-icon-hover.png'
    },
    {
      id: 'knowledge',
      dictName: '我的文档',
      imgUrl: 'icons/knowledge-icon.png',
      hoverImgUrl: 'icons/knowledge-icon-hover.png'
    }
  ])
  static currMode=ref('noMode');//当前的模式
  static setCurrMode(value: string) {
    if (Data.currMode.value != value) {
      if(Data.currMode.value=='lwss'){
        ElementTip.successTip("联网搜索已关闭")
      }
      if(value=='lwss'){
        ElementTip.successTip("联网搜索已开启")
      }
      Data.currMode.value = value
      
      // Data.setShowPopover(true)
    }
  }
  static pdfShowSrc=ref("");//pdf的预览地址
  // static resourceType=ref<any>([{id:"xiaohong",dictName:"小鸿助教"},{id:"application",dictName:"教育应用"},{id:"knowledge",dictName:"我的知识库"}])
  static currShowType = ref<string>('no-data') // xh-input no-data editor
  static saveTime=ref<number>(0);//文档保存的时间
  static streamUrl = import.meta.env.VITE_BASE_URL + import.meta.env.VITE_XIAOXI_URL //stream流地址
  static currFileId = ref('')


  static token = ref<string>('')
  static userInfo = ref<userInfo>() //用户信息
static pptIndexLoadingType=ref(3)//ppt初始的状态，3代表初始化完成  1还未返回数据  2以后返回数据但没有初始化完成
  static isShowAgree = ref(false) //是否展示隐私协议
static initPptData=ref<any>({})
  //教案生成设置数据
  static basicSettingData = ref<basicSetType>()
  static elementIsDraging=ref(false);//ppt的item是否正在拖拽中
  //如果AI内容生产中，是文本框不能编辑
  static isOpenXhSeleteBtn=ref(false);//是否弹出面板正在AI生产内容
  //演讲稿、活动策划生成设置数据
  static workSettingData = ref<any>()

  //是否登录
  static isLogin = ref(false)

  //当前对话id sessionId
  static sessionId = ref<string>()

  static isReg = ref(false) //是否注册过

  static isTryOutAuthority = ref(true) //是否有体验权限

  static isVipExpiration = ref(false) //会员是否到期 true 到期  false 未到期

  //11未提交申请 ，12 已提交未审核  13 审核通过 14 审核未通过  15 会员已过期
  static lv = ref('');

  //二维码合集
  static qrCodeList = ref<any>()

  //是否在有时间限制的活动期   false切换常规邀请活动
  static isActivityOpen = ref(false)

  //邀请人openId
  static inviteOpenId = ref('')

  //新用户剩余次数
  static lastCanCountByUser = ref(0)

  //我的文档当前选中类别
  static myFileType = ref<any>({
    id: 0,
    name: '教案',
    resourceType: 'lesson',
    iconImg: 'icons/teachPlain-icon.png',
    exampleImg: 'icons/file-lesson-img.png',
    itemWidth: 172
  })

  static video: HTMLVideoElement | undefined //相机视频流

  static tihuanItemDateId = ref<number>(0) //替换图片的id

  // static isOnline = ref(2) //是否在联网模式下  1 为联网  2 为不联网
  // static setIsOnline(isOnline: number) {
  //   if (Data.isOnline.value != isOnline) {
  //     //联网模式发送变化
  //     Data.isOnline.value = isOnline
  //     Data.setShowPopover(true)
  //   }
  // }

  static timeId: any
  // static showPopover = ref(false) //是否联网的tip提示
  // static setShowPopover(vale: boolean) {
  //   clearTimeout(Data.timeId)
  //   Data.showPopover.value = vale
  //   if (Data.showPopover.value) {
  //     Data.timeId = setTimeout(() => {
  //       console.log('>>>>23sd')
  //       Data.showPopover.value = false
  //     }, 3000)
  //   }
  // }

  static currClickAnliItemData = ref<any>() //当前点击的案例的数据

  static isShowUpLoadDrawer = ref(false) //是否显示上传选择面板

  static uploadState = ref(1) //上传的状态  1没有上传状态  2 上传中
  static uploadStateWjId = ref('') //上传的状态  1没有上传状态  2 上传中

  static photographFrome = ref(1) //拍照来源 1 作文批改  2 上传文件

  static uploadDocumentsData = reactive({
    wjGroup: '',
    documentsData: [] as any[]
  }) //上传文档数据
}
