<!--上线活动备份-->
<script setup lang="ts">
import { onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue'
import emitter from '@/stores/mitt'
import { Data } from '@/stores/data'
import useClipboard from 'vue-clipboard3'
import { ElementTip } from '@/stores/ElementTip'
import { ElMessage } from 'element-plus'
import { HTTP } from '@/network/http'

import { Base64 } from 'js-base64'
import JlPop from '@/components/Activity/jlPop.vue'
import { VantToast } from '@/utils/VantToast'
import ReceivePrizePop from '@/components/Activity/receivePrizePop.vue'

let def = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  dialogType: {
    type: String,
    default: ''
  },
  dialogTitle: {
    type: String,
    default: ''
  }
})

const mainDialogVisible = ref(def.dialogVisible)
const $emit = defineEmits(['closeDialog'])
const dayNum = ref('0')
const peopleNum = ref('0')
const isShowInviteList = ref(false)
const ruleList = ref<any>([
  {
    title: '邀请新用户登录小鸿备课奖励',
    content:
      '您可点击【复制链接 邀请老师】按钮，邀请老师使用小鸿备课；每成功邀请一名新用户，您即可获得30天小鸿会员体验卡，您邀请的老师可获得10次体验次数。'
  },
  {
    title: '奖励两端同步',
    content: '小鸿备课的使用次数与会员天数与小鸿助教小程序同步更新。'
  },
  {
    title: '邀请用户成功判定',
    content:
      '1.您的好友需未使用过小鸿助教小程序和小鸿备课平台\n' +
      '2.您需要通过您的专属邀请链接邀请好友，同时您的好友需要通过点击您分享的链接在网页端登录小鸿备课，才会被认定为邀请成功。'
  },
  {
    title: '注意事项',
    content:
      '用户不得以任何不正当手段参与活动。若发现此次裂变活动有作弊行为（包括但不限于恶意刷量、利用系统bug或其他技术手段伪造交易数据等）的，将取消用户发奖资格并全额收回所发奖励。因作弊行为导致小鸿助教遭受经济损失或用户投诉等问题的，作弊人员还应负责解决并承担相应的赔偿责任。在法律法规允许的范围内，小鸿助教有权对活动规则进行调整，相关变动以活动页面显示为准。'
  }
])

const specialRuleList = ref<any>([
  {
    title: '活动奖励',
    content:
      '1.邀请人\n' +
      '    每邀请成功满10人，送粉笔套一套+30天小鸿助教会员\n' +
      '    每邀请成功满15人，送翻页笔1支+30天小鸿助教会员\n' +
      '    每邀请成功满30人，送小蜜蜂一个+30天小鸿助教会员\n' +
      '2.被邀请人\n' +
      '    可获得10次体验次数及参与活动的机会'
  },
  {
    title: '邀请用户成功判定',
    content:
      '1.您的好友需未使用过小鸿助教小程序和小鸿备课平台。\n' +
      '2.您需要通过您的专属邀请链接邀请好友，同时您的好友需要通过点击您分享的链接在网页端登录小鸿备课，才会被认定为邀请成功。'
  },
  {
    title: '奖品须知',
    content:
      '1、实物奖品限量，先到先得，具体剩余数量以实时显示数量为准 \n' +
      '2、翻页笔和小蜜蜂奖品二选一，点击二者中任意一个奖品填写收件地址后，将无法再领取另一奖品 \n' +
      '3、奖品是否领取成功，以是否填写完并提交邮寄地址为准 \n' +
      '4、已点亮实物奖品但未提交邮寄地址，后续显示奖品已抢光，则无法填写邮寄地址，视为自动放弃该奖品\n' +
      '5、实物奖品将在活动结束后15个工作日内包邮寄出 \n' +
      '6、小鸿助教网页版的使用次数与会员天数与小鸿助教小程序同步更新'
  },
  {
    title: '注意事项',
    content:
      '1、活动参与用户不得以任何不正当手段参与活动。若发现此次裂变活动有作弊行为(包括但不限于恶意刷量、利用系统bug或其他技术手段伪造交易数据等)的，将取消用户发奖资格并全额收回所发奖励。\n' +
      '活动主办方还将追究作弊人员因作弊行为导致小鸿助教遭受的经济损失或用户投诉等问题的责任及相关赔偿。\n' +
      '2、活动最终解释权归世纪天鸿教育科技股份有限公司所有。\n'
  }
])

// const inviteList = ref<Array<any>>([
//   {
//     openId: '1',
//     schedule: "邀请成功",
//     nickname: '无敌果冻条无敌果冻条无敌果冻条无敌果冻条无敌果冻条无敌果冻条无敌果冻条无敌果冻条无敌果冻条无敌果冻条',
//     getRewards: "30天",
// },{
//     openId: '1',
//     schedule: "邀请成功",
//     nickname: '无敌果冻条无敌果冻条',
//     getRewards: "30天",
//   },{
//     openId: '1',
//     schedule: "邀请成功",
//     nickname: '无敌果冻条无敌果冻条',
//     getRewards: "30天",
//   },{
//     openId: '1',
//     schedule: "邀请成功",
//     nickname: '无敌果冻条无敌果冻条',
//     getRewards: "30天",
//   },
//   {
//     openId: '1',
//     schedule: "邀请成功",
//     nickname: '无敌果冻条无敌果冻条',
//     getRewards: "30天",
//   },
//   {
//     openId: '1',
//     schedule: "邀请成功",
//     nickname: 111, // 0未领取 1已领取
//     getRewards: "30天",
//   },
// ])

const inviteList = ref<Array<any>>([])

// const rankingList = ref<Array<any>>([
//   {
//     sort: 1,
//     nickname: '无敌果冻条无敌果冻条无敌果冻条无敌果冻条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },{
//     sort: 1,
//     nickname: '无敌果冻条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },{
//     sort: 1,
//     nickname: '无敌果冻条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },{
//     sort: 1,
//     nickname: '无敌果冻条条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },
//   {
//     sort: 1,
//     nickname: '无敌果冻条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },
//   {
//     sort: 1,
//     nickname: '无敌果冻条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },
//   {
//     sort: 1,
//     nickname: '无敌果冻条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },{
//     sort: 1,
//     nickname: '无敌果冻条条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },
//   {
//     sort: 1,
//     nickname: '无敌果冻条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },
//   {
//     sort: 1,
//     nickname: '无敌果冻条',
//     inviteNum: 30,
//     headImgUrl:'https://static.yipigai.cn/public/image/23041473-e17c-4b56-af76-0d3955ab933a.jpeg',
//   },
// ])

const rankingList = ref<Array<any>>([])

const scrollbarList = ref<any>([])

// const scrollbarList = ref<array[any]>(
//   ['无****条','1****2','1****3']
// )

const ruleVisible = ref(false)

const randomTime = ref<number>(0)
let timerId: number | null = null

const resultList = ref<Array<any>>([])

interface yqNeedNUmYtpe {
  xhhy: number | string
  fyb: number | string
  kyq: number | string
  [key: string]: number | string // 允许任意字符串键
}
let liucheng = [
  '我邀请不同老师注册小鸿助教',
  '被邀老师注册成功',
  '我可以选择领取对应人数的备选奖品',
  '被邀且成功注册的老师获得10次体验机会'
]

let imgArr: yqNeedNUmYtpe = {
  xhhy: '粉笔套+30天会员',
  fyb: '翻页笔+30天会员',
  kyq: '小蜜蜂+30天会员'
}
let yqNeedNUm: yqNeedNUmYtpe = { xhhy: 10, fyb: 15, kyq: 30 }
let yszs = 30

// let yqNeedNUm: yqNeedNUmYtpe = { xhhy: 1, fyb: 2, kyq: 3 }
// let yszs = 3

let jiangliNum = ref<any>({}) //奖励剩余数量

//测试数据
// let jiangliNum= { 'xhhy': '120', 'fyb': '0', 'kyq': '80' };

let currDf = ref('xhhy') //当前邀请等级
let isShowTip = ref(false)

let errorIp = ref<string>('')
// let inviteList = ref<Array<any>>([]);//邀请人列表
// let inviteIsRegNum = ref(0);//邀请的已注册的总人数
let inviteNum = ref(0) //邀请的总人数
let currPage = ref(2) //获取当前第几页的列表
let loading = ref(false) //获取列表的loding
let bfb = ref(0) //百分比
let isShowLqjlPop = ref(false) //是否显示领取奖励的弹窗
let LqjlPopType = ref('') //领取的奖励类型

let isReg = ref(false) //是否注册
let isShowPopView = ref(false) //是否显示提示去注册弹窗
let yLqLwType = ref<Array<string>>([]) //已领取的礼物数据
let isOver = ref(false) //活动是否已结束

let isOpenGuizhePop = ref(false)

let isShowReceivePop = ref(false)
let externalUrl = ref('')
let isShowHdts = ref(false)

let currentReceiveType = ref('')
let isSoldOut = ref(false) //奖品是否还有库存

let newTimerId: number | null = null

function closeDialog() {
  mainDialogVisible.value = false
  clearTimeout(timerId)
  $emit('closeDialog', def.dialogVisible)
}

const getRandomTime = () => {
  // 生成一个 1000 到 10000 毫秒之间的随机时间
  return Math.floor(Math.random() * (5 - 2)) + 2
}
const startTimer = () => {
  // 更新随机时间
  randomTime.value = getRandomTime() * 1000
  console.log(randomTime.value)
  // 清除之前的定时器（如果有）
  if (timerId !== null) {
    clearTimeout(timerId)
  }

  // 设置新的定时器
  timerId = setTimeout(() => {
    getRelationshipBindingResult()
    startTimer()
  }, randomTime.value)
}

// const newStartTimer = () => {
//   // 更新随机时间
//   // 清除之前的定时器（如果有）
//   if (newTimerId !== null) {
//     clearTimeout(newTimerId)
//   }
//
//   // 设置新的定时器
//   newTimerId = setTimeout(() => {
//     getInitData()
//     newStartTimer()
//   }, 60000)
// }

onUnmounted(() => {
  if (timerId !== null) {
    clearTimeout(timerId)
  }
  if (newTimerId !== null) {
    clearTimeout(newTimerId)
  }
})

init()

function init() {
  if (Data.isActivityOpen.value) {
    //1031实物奖品活动
    getInitData()
  } else {
    //日常拉新活动
    getRelationshipBindingResult()
  }

  getWinningList()
}

//日常拉人活动 、1015上线拉人活动（弃用）
function getRelationshipBindingResult() {
  HTTP.getRelationshipBindingResult()
    .then((res) => {
      console.log(res)
      if (res.code == 200) {
        console.log(res)
        dayNum.value = res.data.dayNum
        peopleNum.value = res.data.peopleNum
        if (res.data.userList != null) {
          inviteList.value = res.data.userList
        } else {
          inviteList.value = []
        }
        if (res.data.theCharts != null) {
          rankingList.value = res.data.theCharts.splice(0, 10)
        } else {
          rankingList.value = []
        }
        if (res.data.scrollBar != null) {
          for (let i = 0; i < res.data.scrollBar.length; i++) {
            let item = res.data.scrollBar[i]
            res.data.scrollBar[i] = encryptionName(item)
            console.log('11111', encryptionName(item))
            console.log('222222', res.data.scrollBar[i])
          }
          scrollbarList.value = res.data.scrollBar
        } else {
          scrollbarList.value = []
        }

        //1015上线活动 过期不用
        // if (Data.isActivityOpen.value) {
        //   startTimer()
        // }
      }
    })
    .catch((error) => {
      console.error('接口调用出错:', error)
    })
}

function getWinningList() {
  HTTP.getWinningList().then((res) => {
    console.log(res)
    if (res.code == 200) {
      console.log(res)
      if (res.data == null || res.data.length == 0) {
        resultList.value = []
      } else {
        resultList.value = res.data
      }
    }
  })
}

function encryptionName(name: any) {
  if (name && name.length > 2) {
    return name.substring(0, 1) + '***' + name.substring(name.length - 1)
  } else {
    return name.substring(0, 1) + '****'
  }
}

function showInviteList() {
  isShowInviteList.value = true
}
function copyLink() {
  const invite_code = Base64.encode(Data.userInfo?.value.openid)
  console.log(invite_code)
  // const url = window.location.href; //获取url中"?"符后的字串
  // console.log(">>window.location.href>>",url)
  // const targetIndex = window.location.href.indexOf("#");
  // const newUrl = window.location.href.substring(0, targetIndex)
  // console.log(">>newUrl>>",newUrl)
  let link =
    '【小鸿助教】99%的老师都知道这款教师AI工具有多好用！教案、课件一键生成，作业批改、工作事务也能帮你一键处理，工作效率翻倍，快来试试看（建议复制到电脑端使用，体验更棒）' +
    'https://xiaohongai.cn/#/application?invite_code=' +
    invite_code
  if (import.meta.env.VITE_NODE_ENV != 'production') {
    link =
      '【小鸿助教】99%的老师都知道这款教师AI工具有多好用！教案、课件一键生成，作业批改、工作事务也能帮你一键处理，工作效率翻倍，快来试试看（建议复制到电脑端使用，体验更棒）' +
      'https://ikeben.cn/chat/xh_assistant_pc/home/#/application?invite_code=' +
      invite_code
    // link = '【小鸿助教】99%的老师都知道这款教师AI工具有多好用！教案、课件一键生成，作业批改、工作事务也能帮你一键处理，工作效率翻倍，快来试试看（建议复制到电脑端使用，体验更棒）' + 'https://pc.ikeben.cn/#/application?invite_code=' + invite_code
  }

  // const link='【小鸿助教】99%的老师都知道这款教师AI工具有多好用！教案、课件一键生成，作业批改、工作事务也能帮你一键处理，工作效率翻倍，快来试试看（建议复制到电脑端使用，体验更棒）' + import.meta.env.VITE_BASE_URL+'?invite_code='+invite_code
  copy(link)
}
// 使用插件
const { toClipboard } = useClipboard()
const copy = async (msg: string) => {
  try {
    // 复制
    await toClipboard(msg)
    // ElementTip.successTip('链接复制成功，快去分享给好友吧～')
    ElMessage({
      type: 'success',
      message: '链接复制成功，快去分享给好友吧～',
      plain: true
    })
    // ElementTip.successTip('复制成功')
    // 复制成功
  } catch (e) {
    ElementTip.errorTip('复制失败')
  }
}

function closeInviteList() {
  isShowInviteList.value = false
}

function showRuleDialog() {
  ruleVisible.value = true
}

/*1131实物奖品活动*/


watch(
  () => inviteNum.value,
  (newValue, oldValue) => {
    // const currentText = textarea.value;
    bfb.value = (newValue / yszs) * 100
    if (bfb.value > 100) {
      bfb.value = 100
    } else if (bfb.value < 0) {
      bfb.value = 0
    }
    if (newValue < 10) {
      currDf.value = 'xhhy'

      // qsNUm.value= yqNeedNUm[]
    } else if (newValue >= 10 && newValue < 15) {
      currDf.value = 'fyb'

    } else if (newValue >= 15) {
      currDf.value = 'kyq'
    }
    console.log(currDf.value, ' bfb.value>>>', bfb.value)
  }
)


//仅供测试用
// watch(
//   () => inviteNum.value,
//   (newValue, oldValue) => {
//     // const currentText = textarea.value;
//     bfb.value = (newValue / yszs) * 100
//     if (bfb.value > 100) {
//       bfb.value = 100
//     } else if (bfb.value < 0) {
//       bfb.value = 0
//     }
//     if (newValue < 1) {
//       currDf.value = 'xhhy'
//
//       // qsNUm.value= yqNeedNUm[]
//     } else if (newValue >= 1 && newValue < 2) {
//       currDf.value = 'fyb'
//
//     } else if (newValue >= 2) {
//       currDf.value = 'kyq'
//     }
//     console.log(currDf.value, ' bfb.value>>>', bfb.value)
//   }
// )

//活动数据查询
function getInitData() {
  const data = {
    openid: Data.userInfo.value?.openid,
    inviter: ''
  }
  HTTP.getActInitData(data as any)
    .then((data: any) => {
      if (data.code == 200) {
      } else {
        VantToast.showDialog(data.descb, () => {
          console.log('>>>点击了确定')
        })
      }
      if (data.data) {
        let _data = data.data
        console.log('>>_data>>', _data)

        //测试数据
        // isReg.value = true;
        // jiangliNum.value = { 'xhhy': '20', 'fyb': '0', 'kyq': '10' };
        // inviteNum.value = 40
        // inviteIsRegNum.value =80
        // inviteList.value = [{
        //   "nickname": "1",
        //   "openid": "o21r26poRHr_XvYwOXNaniLm8Oho",
        //   "isReg": true
        // }, {
        //   "nickname": "222",
        //   "openid": "o21r26poRHr_XvYwOXNaniLm8Oho",
        //   "isReg": true
        // }, {
        //   "nickname": "3333",
        //   "openid": "o21r26poRHr_XvYwOXNaniLm8Oho",
        //   "isReg": true
        // }, {
        //   "nickname": "44444444444444444",
        //   "openid": "o21r26poRHr_XvYwOXNaniLm8Oho",
        //   "isReg": true
        // }, {
        //   "nickname": "55555555555555",
        //   "openid": "o21r26poRHr_XvYwOXNaniLm8Oho",
        //   "isReg": true
        // }];
        // yLqLwType.value = ['']
        // isOver.value = false;
        // return

        if (_data.gift) {
          jiangliNum.value = _data.gift
        }
        if (_data.inviteCount) {
          // inviteNum.value = _data.inviteCount
          inviteNum.value = _data.inviteCount
          console.log(_data.inviteList, '>>inviteList>>', inviteList.value)
        }
        //PC端没有inviteIsRegNum
        // if (_data.inviteIsRegNum) {
        //   inviteIsRegNum.value = _data.inviteIsRegNum
        // }
        if (_data.inviteList) {
          inviteList.value = _data.inviteList
          console.log(_data.inviteList, '>>inviteList>>', inviteList.value)
        }
        if (_data.yLqLwType) {
          yLqLwType.value = _data.yLqLwType
        }
        isReg.value = _data.isReg
        isShowPopView.value = !isReg.value
        // isOver.value = _data.isOver

        isOver.value = _data.isOver
        // if (Data.isActivityOpen.value) {
        //   newStartTimer()
        // }
      }
    })
    .catch((error) => {
      console.log(error)
      if(error.code == 400){
        // 您已完成任务并领取奖励
        if (error.descb.includes('您已完成任务并领取奖励')){
          console.log('>>error.descb>>', error.descb)
        } else {
          ElementTip.errorTip(error.descb)
        }
        if (error.data) {
          let _data = error.data
          console.log('>>_data>>', _data)
          if (_data.gift) {
            jiangliNum.value = _data.gift
          }
          if (_data.inviteCount) {
            // inviteNum.value = _data.inviteCount
            inviteNum.value = _data.inviteCount
            console.log(_data.inviteList, '>>inviteList>>', inviteList.value)
          }
          //PC端没有inviteIsRegNum
          // if (_data.inviteIsRegNum) {
          //   inviteIsRegNum.value = _data.inviteIsRegNum
          // }
          if (_data.inviteList) {
            inviteList.value = _data.inviteList
            console.log(_data.inviteList, '>>inviteList>>', inviteList.value)
          }
          if (_data.yLqLwType) {
            yLqLwType.value = _data.yLqLwType

          }
          isReg.value = _data.isReg
          isShowPopView.value = !isReg.value
          // isOver.value = _data.isOver

          isOver.value = _data.isOver
        }
      } else {
        ElementTip.errorTip(error.descb)
      }

    })
}

//点击领取礼物按钮
function lqliwu(key: string) {
  HTTP.checkSyNum(key)
    .then((data: any) => {
      if (data.code == 200) {
        if (key == 'xhhy') {
          isShowReceivePop.value = true
          LqjlPopType.value = key
        } else {
          isShowLqjlPop.value = true
          isShowHdts.value = true
          LqjlPopType.value = key
          console.log(key)
        }
        isSoldOut.value = false
      }
      if (data.data) {
        let _data = data.data
        if (_data.gift) {
          jiangliNum.value = _data.gift
        }
        if (_data.yLqLwType) {
          yLqLwType.value = _data.yLqLwType
        }
      }
    })
    .catch((error) => {
      if (error.code == 400) {
        //活动奖品库存为空
        showSoldOutPop(key)
      }else {
        //其他报错
        ElementTip.errorTip(error.descb)
      }
    })
}

//领取奖励接口
function getJl(jlName: string) {
  isShowLqjlPop.value = false
  isShowReceivePop.value = true
  LqjlPopType.value = jlName

  //粉笔套填写过收货地址后 后续不再填写 目前接口还没改
  // if(yLqLwType.value.includes('xhhy')) {
  //   const data = {
  //     prize: jlName,
  //     openid: Data.userInfo.value?.openid
  //   }
  //   HTTP.getCollect(data as any)
  //     .then((res) => {
  //       console.log(res)
  //       if (res.code == 200) {
  //         console.log(res)
  //         isShowLqjlPop.value = true;
  //         isShowHdts.value = false;
  //         LqjlPopType.value = jlName;
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('接口调用出错:', error)
  //     })
  // }
}


//关闭领取礼物弹窗
function closeOpenImgUrl() {
  isShowLqjlPop.value = false
  LqjlPopType.value = ''
  getInitData()
}

//库存为空
function showSoldOutPop(jlName: string) {
  LqjlPopType.value = jlName
  isSoldOut.value = true
  isShowLqjlPop.value = true
  isShowHdts.value = false
}

function showLastPop(jlName: string) {
  console.log(jlName)
  isShowReceivePop.value = false
  LqjlPopType.value = jlName
  isShowLqjlPop.value = true
  isShowHdts.value = false
}

function closeReceivePoP() {
  isShowReceivePop.value = false
  LqjlPopType.value = ''
  getInitData()
}
</script>

<template>
  <div class="main-dialog" v-if="dialogType == 'activity-main'"
    :class="!Data.isActivityOpen.value ? 'common-main-dialog' : ''">
    <el-dialog v-model="mainDialogVisible" :close-on-click-modal="true" :show-close="false" @close="closeDialog">
      <template #header="{ titleId, titleClass }">
        <div class="common-my-header" v-if="!isShowInviteList" :class="Data.isActivityOpen.value ? 'my-header' : ''">
          <span :id="titleId" :class="titleClass" class="head-title">{{ dialogTitle }}</span>
          <div @click="closeDialog" class="close-btn" style="">
            <img style="width: 16px; height: 16px" src="@/assets/activity/act-close-icon.png" alt="" />
          </div>
        </div>
        <div class="list-header" v-else>
          <div @click="closeInviteList" class="list-close-btn">
            <img style="width: 16px; margin-left: 16px; float: left" src="@/assets/activity/arrow-left.png" alt="" />
          </div>
          <span :id="titleId" :class="titleClass">邀请记录</span>
          <div @click="closeDialog" class="list-close-btn" style="">
            <img style="width: 16px; height: 16px" src="@/assets/activity/act-close-icon.png" alt="" />
          </div>
        </div>
      </template>
      <div class="dialog-body" v-if="!isShowInviteList">
        <div class="act-header" :class="!Data.isActivityOpen.value ? 'common-act-header' : ''">
          <img v-show="Data.isActivityOpen.value" src="@/assets/activity/1031/dialog-header.png" alt="" />
          <img v-show="!Data.isActivityOpen.value" src="https://static.yipigai.cn/public/activity/common-act-header.png"
            alt="" />
          <!--          <img src="@/assets/activity/act-header-common.png" alt="">-->
        </div>
        <div class="act-ctr" :class="!Data.isActivityOpen.value ? 'common-act-ctr' : ''">
          <div v-show="Data.isActivityOpen.value" class="rule-btn" @click="showRuleDialog">
            活动说明
          </div>
          <div class="rule-dialog">
            <el-dialog v-model="ruleVisible" width="431"
              style="border-radius: 16px; height: 85%; margin-top: 60px; overflow-y: auto" title="活动说明" append-to-body>
              <div>
                <div class="rule-dialog-ctr">
                  <div v-for="(ruleItem, index) in specialRuleList" :key="index" class="rule-dialog-item">
                    <div class="rule-title">
                      {{ ruleItem.title }}
                    </div>
                    <div class="rule-content">
                      {{ ruleItem.content }}
                    </div>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
          <div class="invite-prize" v-if="Data.isActivityOpen.value">
            <div class="ctr-title">
              <div class="title-content">活动奖励</div>
            </div>
            <div class="py-ctr hshe-color">
              <div v-for="(item, key) in imgArr" :key="key" class="yaoqing-per">
                <div>
                  <div class="jl-title">{{ item }}</div>
                  <div class="jl-img" :class="currDf == key ? 'seleceteImg' : ''">
                    <div class="kcbz-ctr" v-if="parseInt(String(jiangliNum[key as keyof typeof jiangliNum])) <= 0">
                      <img src="@/assets/activity/1031/soldOut.png" alt="" />
                    </div>
                    <img :src="'activity1031/' + key + '.png'" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="progress" :class="bfb != 100 ? 'yj-dev' : ''">
              <van-progress :percentage="bfb" pivot-text="." pivot-color="#fff" text-color="#FF9A03" track-color="#FFD5D3"
                :stroke-width="12" color="#FF9A03" />
            </div>
            <div class="ly-btn-ctr">
              <div v-for="(item, key) in imgArr" :key="key" class="lq-ctr">
                <div class="lqlw-btn" v-if="yLqLwType.indexOf(key as string) != -1">已领取</div>
                <div class="lqlw-btn" v-else-if="
                  (yLqLwType.indexOf('fyb') != -1 || yLqLwType.indexOf('kyq') != -1) &&
                  key != 'xhhy'
                ">
                  {{ yLqLwType.indexOf(key as string) != -1 ? '已领取' : '无法领取' }}
                </div>
                <!-- <div class="lqlw-btn" v-else-if="key != 'xhhy'&& parseInt(jiangliNum[key])<=0">库存不足</div> -->

<!--                库存不足的时候，点领取礼物只给30天会员-->
                <div class="lqlw-btn" :class="
                  inviteNum >= (yqNeedNUm[key] as number) &&
                    jiangliNum[key as keyof typeof jiangliNum] >= 0
                    ? 'canClick'
                    : ''
                " @click="lqliwu(key as string)" v-else>
                  领取礼物
                </div>
                <div class="jiangli-sy" v-if="jiangliNum[key as keyof typeof jiangliNum]">
                  <span class="sy">剩余:</span> {{ jiangliNum[key as keyof typeof jiangliNum]
                  }}<span>件</span>
                </div>
              </div>
            </div>
            <p class="yaoqing-tip" v-if="
              inviteNum < yszs  && yLqLwType.indexOf('fyb') == -1 && yLqLwType.indexOf('kyq') == -1
            ">
              还差<span class="hshe-color">{{ (yqNeedNUm[currDf] as number) - inviteNum }}</span>名老师，即可获得{{ imgArr[currDf]
              }}
            </p>
            <p class="yaoqing-tip" v-else-if="yLqLwType.indexOf('fyb') != -1 || yLqLwType.indexOf('kyq') != -1">
              您已领取奖励
            </p>
            <div class="yaoqing-bottom" @click="copyLink">复制链接 邀请老师</div>
            <p class="yaoqing-tip-link" @click="showInviteList">点击查看邀请成功记录</p>
          </div>
          <div class="invite-prize" v-if="!Data.isActivityOpen.value">
            <div class="ctr-title">
              <div class="title-content">邀请奖励</div>
            </div>
            <!--            常规活动-->
            <div class="normal-act">
              <div class="">
                <img class="prize-img" style="width: 190px"
                  src="https://static.yipigai.cn/public/activity/common-prize-left.png" alt="" />
                <img class="prize-img" style="width: 190px; float: right"
                  src="https://static.yipigai.cn/public/activity/common-prize-right.png" alt="" />
              </div>
            </div>
            <el-button class="invite-btn" @click="copyLink">复制链接 邀请老师</el-button>
          </div>
          <div class="invite-prize" style="position: relative" v-if="!Data.isActivityOpen.value">
            <div class="ctr-title">
              <div class="title-content">我的邀请</div>
            </div>
            <div class="check-btn" @click="showInviteList">
              <span>查看详情</span>
              <img style="width: 14px; margin-left: 4px" src="@/assets/activity/arrow-right.png" alt="" />
            </div>
            <div class="my-prize">
              <div>
                <div class="prize-total">
                  <span class="prize-num">{{ dayNum }}</span>天
                </div>
                <div>已获使用时间</div>
              </div>
              <div>
                <div class="my-prize-line"></div>
              </div>
              <div>
                <div class="prize-total">
                  <span class="prize-num">{{ peopleNum }}</span>人
                </div>
                <div>成功邀请老师</div>
              </div>
            </div>
          </div>
          <div class="invite-prize" v-show="!Data.isActivityOpen.value">
            <div class="ctr-title">
              <div class="title-content">活动规则</div>
            </div>
            <div v-for="(ruleItem, index) in ruleList" :key="index" class="rule-ctr">
              <div class="rule-title">
                {{ ruleItem.title }}
              </div>
              <div class="rule-content">
                {{ ruleItem.content }}
              </div>
            </div>
          </div>
          <div class="invite-prize" v-show="Data.isActivityOpen.value">
            <div class="ctr-title">
              <div class="title-content">活动流程</div>
            </div>
            <div class="liucheng-ctr">
              <div v-for="(item, index) in liucheng" :key="index" class="liucheng-item">
                <div class="line" v-if="index != liucheng.length - 1"></div>
                <div class="liucheng-num">{{ index + 1 }}</div>
                <div class="liucheng-text">{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-body list-ctr" v-else>
        <div class="record-list-ctr">
          <div class="py-list" :class="!Data.isActivityOpen.value ? 'common-py-list' : ''">
            <div class="list-title">
              <div class="list-item long-item" :class="Data.isActivityOpen.value ? 'special-item' : ''">
                被邀请人
              </div>
              <div class="list-item short-item" :class="Data.isActivityOpen.value ? 'special-item' : ''">
                邀请进度
              </div>
              <div class="list-item last-item" v-show="!Data.isActivityOpen.value">获得时长</div>
            </div>
            <div class="list-body">
              <div class="list-item-per" v-show="inviteList && inviteList.length > 0">
                <div class="list-item-ctr" v-for="item in inviteList" :key="item?.openid"
                  :class="Data.isActivityOpen.value ? 'special-item-ctr' : ''">
                  <div class="list-item long-item" :class="Data.isActivityOpen.value ? 'special-item' : ''">
                    {{ item?.nickname ? item?.nickname : '小鸿用户'+item?.openid.slice(-6) }}
                  </div>
                  <div class="list-item short-item" :class="Data.isActivityOpen.value ? 'special-item' : ''">
                    邀请成功
                  </div>
                  <div class="list-item last-item" v-show="!Data.isActivityOpen.value">30天</div>
                </div>
              </div>
              <div class="no_data" v-if="inviteList.length == 0">
                <img v-show="Data.isActivityOpen.value" class="list-null" src="@/assets/activity/1031/list-null.png"
                  alt="" />
                <img v-show="!Data.isActivityOpen.value" class="list-null"
                  src="https://static.yipigai.cn/public/activity/common-list-null.png" alt="" />
                暂无信息，邀请好友获取福利吧
              </div>
              <div class="list-btn-ctr all_data" v-else>已加载全部</div>
            </div>
          </div>
        </div>
      </div>

      <JlPop v-if="isShowLqjlPop && LqjlPopType" :LqjlPopType="LqjlPopType" :isShowHdts="isShowHdts" :isSoldOut="isSoldOut"
             @closeOpenImgUrl="closeOpenImgUrl" @getJl="getJl" class="zhezhao"></JlPop>
      <ReceivePrizePop v-if="isShowReceivePop && LqjlPopType" :LqjlPopType="LqjlPopType" class="zhezhao"
                       @closeReceivePrizePoP="closeReceivePoP" @showSoldOutPop="showSoldOutPop" @showLastPop="showLastPop">
      </ReceivePrizePop>
    </el-dialog>

    <!--    <div v-if="isShowReceivePop" class="zhezhao">-->
    <!--      <div class="receive-link">-->
    <!--        <iframe :src="externalUrl" width="368px" height="600px"></iframe>-->
    <!--        <div class="link-close" @click="closeExternalLink">-->
    <!--          <img src="@/assets/activity/1031/close.png" alt="avatar">-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </div>-->
  </div>
  <div class="result-dialog" v-if="dialogType == 'activity-result'">
    <el-dialog v-model="mainDialogVisible" :close-on-click-modal="true" :show-close="false" @close="closeDialog">
      <template #header="{ titleId, titleClass }">
        <div class="result-my-header">
          <span :id="titleId" :class="titleClass">{{ dialogTitle }}</span>
          <div @click="closeDialog" class="result-close-btn" style="">
            <img style="width: 16px; height: 16px" src="@/assets/activity/act-close-icon.png" alt="" />
          </div>
        </div>
      </template>
      <div class="result-dialog-body">
        <div class="act-result-header">
          <img src="@/assets/activity/activity-result-header.png" alt="" />
          <!--          <img src="@/assets/activity/act-header-common.png" alt="">-->
        </div>
        <div class="act-result-ctr">
          <div class="invite-prize rank-ctr" v-show="!Data.isActivityOpen.value">
            <div class="result-rank-list">
              <div class="rank-list-title result-list-title">
                <div class="rank-short-item">排名</div>
                <div class="rank-long-item" style="padding-left: 12px">用户</div>
                <div class="rank-middle-item">邀请人数</div>
              </div>
              <div class="rank-list-body">
                <div class="list-item-per">
                  <div class="rank-body-ctr" v-for="(rankItem, index) in resultList" :key="index" :class="
                    index == 0
                      ? 'first-rank'
                      : index == 1
                        ? 'second-rank'
                        : index == 2
                          ? 'third-rank'
                          : ''
                  ">
                    <div class="rank-short-item">
                      <img class="rank-img" v-if="index == 0" src="@/assets/activity/rank-1.png" alt="" />
                      <img class="rank-img" v-else-if="index == 1" src="@/assets/activity/rank-2.png" alt="" />
                      <img class="rank-img" v-else-if="index == 2" src="@/assets/activity/rank-3.png" alt="" />
                      <span class="rank-number" v-else>{{ index + 1 }}.</span>
                    </div>
                    <div class="rank-long-item">
                      <img class="rank-headImg" :src="rankItem?.headImgUrl" alt="" />
                      <div class="rank-list-item">
                        {{ rankItem?.nickname ? rankItem?.nickname : '未命名' }}
                      </div>
                    </div>
                    <div class="rank-middle-item invite-num">
                      {{ rankItem?.count }}
                    </div>
                  </div>
                </div>
                <div class="no_data" v-if="resultList.length == 0">暂无信息，敬请期待</div>
              </div>
            </div>
          </div>
        </div>
        <div class="result-tip">请获奖者在11月15日之前联系客服领取奖品，过期视为主动放弃。</div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.zhezhao {
  width: 100%;
  height: 100%;
  background: #0000005e;
  position: absolute;
  z-index: 2005;
  left: 0;
  top: 0;
}

.receive-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 368px;
  margin: auto;
  height: 100%;
}

.link-close {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 25%;
  right: 10%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.link-close img {
  width: 16px;
  height: 16px;
}

.main-dialog {
  :deep(.el-dialog) {
    width: 460px;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgba(158, 172, 229, 0.3);
    padding: 0;
    background: linear-gradient(180deg, #c0f2fc 0%, #e3fed3 41%, #c9f4fa 100%);
    background-repeat: no-repeat;
    background-image: url('@/assets/activity/1031/dialog-bg.png');
    background-size: cover;
    position: relative;
    z-index: 100;
    max-height: 90%;
    margin-top: 48px;
    overflow-y: auto;

    .el-dialog__header {
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
      align-items: center;
      font-variation-settings: 'wght' 600;
      color: #040a39;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 16px 16px 0 0;
      display: flex;
      padding: 0;
    }

    .el-dialog__headerbtn {
      height: 58px;
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
    }
  }
}

.common-main-dialog {
  :deep(.el-dialog) {
    background-repeat: no-repeat;
    background-image: url('https://static.yipigai.cn/public/activity/common-maindialog-bg.png');
    background-size: cover;
  }
}

.rule-dialog {
  :deep(.el-dialog) {
    width: 431px;
    border-radius: 12px;
    height: 90%;
    margin-top: 48px;
    overflow-y: auto;

    .el-dialog__header {
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
      align-items: center;
      font-variation-settings: 'wght' 600;
      color: #040a39;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 16px 16px 0 0;
      display: flex;
      padding: 0;
    }

    .el-dialog__headerbtn {
      height: 58px;
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
    }
  }
}

.rule-dialog-item {
  margin-bottom: 16px;
}

.dialog-body {
  width: 100%;
}

.list-ctr {
  background-color: #f6f6fb;
  height: 100%;
  border-radius: 0 0 8px 8px;
}

.common-my-header {
  width: 100%;
  padding: 0 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
}

.my-header {
  width: 100%;
  padding: 0 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  background-color: #ffca58;
}

.head-title {
  padding-top: 16px;
}

.close-btn {
  /*display:inline-block;
  float: right;*/
  width: 80px;
  text-align: center;
  cursor: pointer;
  padding-top: 16px;
}

.list-header {
  width: 100%;
  padding: 16px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6fb;
  border-radius: 12px 12px 0 0;
}

.list-close-btn {
  width: 48px;
  text-align: center;
  cursor: pointer;
}

.act-header {
  position: absolute;
  top: 54px;
  /*z-index: 10;
  height: 181px;*/
  z-index: 1000;
}

.act-header img {
  width: 460px;
}

.common-act-header {
  position: absolute;
  top: 37px;
  /*z-index: 10;
  height: 181px;*/
  z-index: 1000;
}

.act-ctr {
  margin-top: 200px;
  position: relative;
  z-index: 100;
  margin-bottom: 24px;
}

.common-act-ctr {
  margin-top: 181px;
  position: relative;
  z-index: 100;
  margin-bottom: 24px;
}

.list-ctr {}

.ctr-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
  color: #1d2129;

  top: 15px;
  opacity: 1;
  /*background-image: url("@/assets/activity/title-bg.png");
  background-size: 100% 100%;*/
  margin-left: 20px;
  margin-bottom: 16px;
  width: 75px;
  height: 24px;
}

.title-content {
  padding-top: 6px;
  padding-left: 3px;
}

.invite-prize {
  width: 431px;
  margin: auto auto 8px;
  background-color: #ffffff;
  border-radius: 8px;
  padding-top: 12px;
}

.invite-btn {
  background: linear-gradient(270deg, #afeea2 0%, #29b57c 48%, #89c9ed 100%);
  box-shadow: inset 6px -6px 16px 0px rgba(255, 255, 255, 0.5);
  background-size: 400% 400%;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #ffffff;
  width: 367px;
  height: 45px;
  border-radius: 39px;
  //animation: slide 5s ease infinite;
  letter-spacing: 0.01em;
  animation: slide 3s ease infinite;
  border: none;
  margin-bottom: 18px;
  margin-left: 33px;
}

@keyframes slide {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.invite-btn:hover {
  color: #ffffff;
  background: linear-gradient(270deg, #afeea2 0%, #29b57c 48%, #89c9ed 100%);
}

.check-btn {
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: #4e5969;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.my-prize {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 18px;
  font-size: 14px;
  font-weight: normal;
  color: #1d2129;
}

.prize-total {
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  color: #86909c;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 0px;
}

.my-prize-line {
  width: 1px;
  height: 49px;
  opacity: 1;
  background: #ededed;
  margin: 19px 16px 16px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.prize-num {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  //color: #485cec;
  color: #31b785;
  margin-right: 4px;
}

.rule-ctr {
  padding: 0 20px 18px;
}

.rule-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  text-align: justify;
  /* 浏览器可能不支持 */
  color: #1d2129;
  margin-bottom: 8px;
}

.rule-content {
  font-size: 14px;
  font-weight: 350;
  line-height: 23px;
  text-align: justify;
  color: #1d2129;
  white-space: pre-wrap;
}

.record-list-ctr {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.py-list {
  width: 100%;
  height: 703px;
  background: linear-gradient(180deg, #ffe6a2 0%, #fce0d3 97%);
  border-radius: 10px;
  padding: 0 3px 3px;
  white-space: nowrap;
}

.common-py-list {
  background: linear-gradient(181deg, #cdf4ff 0%, #dffdd9 9%, #f8e0bb 83%);
}

.list-title {
  height: 40px;
  color: #040a39;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px 0 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.list-title div {
  /* width: 33%; */
  overflow: hidden;
}

.list-item {
  width: 30%;
  text-overflow: ellipsis;
}

.list-line {
  border-top: 1px solid #f3f3f3;
  width: 401px;
  margin-top: 12px;
}

.long-item {
  width: 152px;
  overflow: hidden;
  /*内容超出后隐藏*/
  text-overflow: ellipsis;
  /*超出内容显示为省略号*/
  white-space: nowrap;
  /*文本不进行换行*/
}

.short-item {
  width: 56px;
  text-align: center;
}

.special-item {
  width: 50%;
}

.last-item {
  width: 56px;
  text-align: center;
}

.list-body {
  height: calc(100% - 42px);
  /* background: #fff; */
  border-radius: 10px;
  /* padding: 0 12px; */
  overflow-y: scroll;
  background: #ffffff;
  font-size: 13px;
  font-weight: 350;
  line-height: 18px;
  color: #1d2129;
  padding-top: 12px;
}

.list-item-per {
  width: 100%;
}

.list-item-ctr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  //margin-bottom: 20px;
  margin: 0 10px 8px;
  //flex-wrap: wrap;
  padding: 0 22px 12px;
  border-bottom: 1px solid #f3f3f3;
}

.special-item-ctr {
  border-bottom: 1px solid #f3f3f3;
}

.list-item-ctr div {
  overflow: hidden;
  /* width: 33%; */
}

.no_data {
  color: #86909c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.list-null {
  width: 72px;
  height: 82px;
}

.list-btn-ctr {
  height: 49px;
  color: #86909c;
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  box-sizing: border-box;
}

.list-btn {
  margin: 14px auto auto;
  width: 157px;
  height: 27px;
  border-radius: 8px;
  background: #40cfaf;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  color: #ffffff;
}

.list-btn-ctr.all_data {
  border-top: none;
}

.rule-btn {
  width: 82px;
  height: 29px;
  opacity: 1;
  background: #773529;
  font-size: 14px;
  font-weight: normal;
  line-height: 29px;
  color: #ffffff;
  text-align: center;
  border-radius: 8px 0 0 8px;
  position: absolute;
  top: 4px;
  right: 0;
  cursor: pointer;
}

.special-act {
  margin-left: 20px;
  margin-right: 20px;
}

.py-ctr {
  display: flex;
  justify-content: center;
  gap: 32px;
  //width: 90%;
  margin: 16px 30px 6px;
}

.hshe-color {
  color: #ff9a03;
}

.yaoqing-per {
  /* max-width: 0.94rem; */
}

.jl-title {
  width: 100%;
  line-height: 22px;
  border-radius: 8px;
  background: #f9cf86;
  font-size: 12px;
  font-weight: normal;
  color: #773529;
  text-align: center;
  margin-bottom: 8px;
}

.seleceteImg {
  filter: drop-shadow(0px 0px 4px #ff9a03);
}

.seleceteImg img {
  filter: drop-shadow(0px 0px 4px #ff9a03);
}

.progress {
  /*width: 90%;*/
  margin: auto 30px 10px;
}

.kcbz-ctr {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.kcbz-ctr img {
  width: 100%;
  height: 100%;
}

.jl-img {
  position: relative;
}

.ly-btn-ctr {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 32px;
  margin: auto 30px;
}

.lq-ctr {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lqlw-btn {
  width: 84px;
  line-height: 28px;
  min-width: 84px;
  border-radius: 85px;
  /* background: #FF4C54; */
  border: 1px solid #ce651a;
  text-align: center;
  color: #ce651a;
  opacity: 0.5;
  pointer-events: none;
}

.lqlw-btn.canClick {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}

.jiangli-sy {
  color: #ce651a;
  font-size: 10px;
  text-align: center;
  line-height: 16px;
  margin-top: 4px;
}

.yaoqing-tip {
  margin-top: 9px;
  text-align: center;
  color: #291d22;
}

.yaoqing-tip-link {
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: #818181;
  padding-bottom: 18px;
  cursor: pointer;
}

.yaoqing-bottom {
  margin: 9px auto 6px auto;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  width: 328px;
  height: 46px;
  border-radius: 35px;
  opacity: 1;
  background: linear-gradient(270deg, #ffca60 0%, #ff9a03 52%, #ffc85b 100%);
  box-sizing: border-box;
  border: 2px solid #ffd98e;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.yaoqing-bottom:hover {
  box-shadow: 0px 4px 10px 0px rgba(242, 168, 93, 0.72);
  transform: scale(1.05);
}

.normal-act {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 11px;
}

.spe-prize-ctr {
  padding-bottom: 16px;
}

.prize-title {
  color: #31b785;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.tip-title {
  color: rgba(49, 183, 133, 0.7);
}

.title-point {
  width: 6px;
  height: 6px;
  opacity: 1;
  background: #31b785;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.prize-img {
  margin: 0;
  padding: 0;
}

.rank-list {
  width: 100%;

  padding: 0 20px;
  white-space: nowrap;
  padding-bottom: 0;
}

.rank-list-title {
  height: 18px;
  color: #4e5969;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  overflow: hidden;
}

.rank-list-item {
  color: #1d2129;
  overflow: hidden;
  /*内容超出后隐藏*/
  text-overflow: ellipsis;
  /*超出内容显示为省略号*/
  white-space: nowrap;
  /*文本不进行换行*/
}

.rank-short-item {
  width: 56px;
  height: 100%;
  text-align: center;
}

.rank-short-item {
  width: 56px;
  text-align: center;
  text-overflow: ellipsis;
  height: 100%;
}

.rank-long-item {
  width: 267px;
  text-overflow: ellipsis;
  padding-left: 8px;
  height: 100%;
  display: flex;
  align-items: center;
}

.rank-middle-item {
  width: 68px;
  text-overflow: ellipsis;
  text-align: center;
  height: 100%;
}

.rank-list-body {
  margin-top: 6px;
  max-height: 568px;
  overflow-y: scroll;
}

.rank-body-ctr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 48px;
  margin-top: 8px;
  height: 48px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #f7f7f7;
}

.rank-headImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 12px;
}

.first-rank {
  background: linear-gradient(90deg, #fff0d8 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 8px;
  border: none;
}

.second-rank {
  background: linear-gradient(90deg,
      #dde3ea 0%,
      rgba(224, 229, 236, 0.9158) 8%,
      rgba(255, 255, 255, 0) 100%);
  border-radius: 8px;
  border: none;
}

.third-rank {
  background: linear-gradient(90deg, #f9e4cd 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 8px;
  border: none;
}

.rank-img {
  width: 21px;
  height: 24px;
  margin-top: 12px;
}

.rank-number {
  font-family: Alimama ShuHeiTi;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #1d2129;
}

.invite-num {
  color: #31b785;
  font-family: Alimama ShuHeiTi;
  font-size: 14px;
  font-weight: bold;
}

.scrollBar {
  width: 147px;
  height: 22px;
  border-radius: 4px;
  background: #fff3e0;
  //overflow: hidden;
  //white-space: nowrap;
}

.rank-ctr {
  position: relative;
}

.scrollBar-ctr {
  width: 147px;
  height: 22px;
  //border-radius: 4px;
  //background: #FFF3E0;
  position: absolute;
  top: 16px;
  right: 30px;
  overflow: hidden;
  white-space: nowrap;
}

.carousel-text {
  width: 147px;
  height: 22px;
  text-align: center;
  font-size: 10px;
  font-weight: normal;
  line-height: 22px;
  color: #d0850f;
  border-radius: 4px;
  background: #fff3e0;
}

.carousel {
  display: inline-flex;
  animation: slide2 1s infinite alternate linear;
}

.carousel-item {
  min-height: 22px;
  /* 根据需要设置每个轮播项的宽度 */
  //margin-right: 10px; /* 轮播项之间的间距 */
  /* 使用CSS变量来计算位置 */
  transform: translateY(calc(var(--carousel-index) * (100% + 0px)));
  /* 动画时长与轮播项数量成正比，以实现无缝轮播效果 */
  animation:
    slide-in 0.5s calc(0.5s * var(--carousel-items)),
    slide-out 0.5s calc(0.5s * var(--carousel-items));
}

@keyframes slide2 {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc((var(--carousel-items) - 1) * (100% + 10px)));
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-22px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(22px);
  }
}

.result-dialog {
  :deep(.el-dialog) {
    width: 460px;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgba(158, 172, 229, 0.3);
    background: #f6f6fb;
    padding: 0;
    position: relative;
    z-index: 100;
    height: 806px;
    margin-top: 48px;
    overflow-y: auto;

    .el-dialog__header {
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
      align-items: center;
      font-variation-settings: 'wght' 600;
      color: #040a39;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 16px 16px 0 0;
      display: flex;
      padding: 0;
    }

    .el-dialog__headerbtn {
      height: 58px;
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
    }
  }
}

.result-my-header {
  background: linear-gradient(90deg, #edf1ff 0%, #eff0fb 99%);
  background-repeat: no-repeat;
  background-image: url('@/assets/activity/actResult-title-color.png');
  background-size: cover;
  width: 100%;
  padding: 0 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
}

.act-result-ctr {
  //margin-top: 87px;
  position: relative;
  z-index: 100;
  margin-bottom: 21px;
}

.result-dialog-body {
  background: #f6f6fb;
}

.act-result-header {
  height: 87px;
}

.result-close-btn {
  /*display:inline-block;
  float: right;*/
  width: 80px;
  text-align: center;
  cursor: pointer;
}

.result-list-title {
  font-size: 14px;
}

.result-rank-list {
  width: 100%;

  padding: 0 20px;
  white-space: nowrap;
  padding-bottom: 0px;
}

.result-tip {
  font-family: Source Han Sans;
  font-size: 14px;
  font-weight: 350;
  line-height: 24px;
  color: #4e5969;
  padding-left: 25px;
  margin-bottom: 13px;
  letter-spacing: 0em;
}

.liucheng-ctr {
  margin-top: 12px;
}

.liucheng-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 90%;
  margin: auto;
  /* margin-bottom: 0.2rem; */
  position: relative;
}

.line {
  border: 1px dashed #cd9973;
  height: 100%;
  /* width: 10px; */
  position: absolute;
  left: 10px;
  z-index: 0;
}

.liucheng-text {
  flex: 1;
  margin-bottom: 20px;
  line-height: 22px;
}

.liucheng-num {
  width: 22px;
  height: 22px;
  opacity: 1;
  background: #e4d6cc;
  border: 2px solid #350b04;
  color: #350b04;
  border-radius: 50%;
  text-align: center;
  z-index: 1;
  line-height: 18px;
  font-family: Alimama ShuHeiTi;
  font-size: 14px;
  font-weight: 700;
}
</style>
<style>
.van-progress .van-progress__pivot {
  width: 20px;
  line-height: 20px;
  background: #ffffff;
  box-sizing: border-box;
  border: 5px solid #ff9a03;
  min-width: 20px;
  height: 20px;
}

.yj-dev .van-progress .van-progress__portion {
  border-radius: 8px 0px 0 8px;
}

.progress .van-progress {
  border-radius: 8px;
  background: #fcdc9c !important;
}
</style>
