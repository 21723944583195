<script setup lang="ts">
import { WarningFilled, Close, Plus } from '@element-plus/icons-vue'
import { reactive, ref, watch, onMounted, onUnmounted, onBeforeUnmount } from 'vue'
import { ElLoading, ElMessage, type FormInstance, type FormRules } from 'element-plus'
import emitter from '@/stores/mitt'
import { Data } from '@/stores/data'
import { Utill } from '@/stores/Utill'
import { HTTP } from '@/network/http'
import { useRouter, useRoute } from 'vue-router'
import { muban } from '@/mocks/muban'
import type { Muban } from '@/types/muban'
import { moduleList } from '@/stores/StaticText'
import { Base64 } from 'js-base64'
const route = useRoute();
let def = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  dialogType: {
    type: String,
    default: ''
  },
  dialogTitle: {
    type: String,
    default: ''
  }
})

const $emit = defineEmits(['submitClick', 'closeDialog', 'submitMembershipForm'])
let defaultDialogVisible = ref(def.dialogVisible)
let loginDialogVisible = ref(def.dialogVisible)
let feedbackDialogVisible = ref(def.dialogVisible)

//测试数据
const TemplateImgList: Array<Muban> = muban
let selePttData = ref<Muban>(TemplateImgList[0])

interface MembershipForm {
  userName: string
  mobileNumber: number
  smsCode: string
  gradeId: number
  subjectId: number
  company: string
  type: string
}

const membershipForm = ref<MembershipForm>({
  userName: '',
  mobileNumber: null,
  smsCode: '',
  gradeId: null,
  subjectId: null,
  company: '',
  type: ''
})
const ruleFormRef = ref<FormInstance>()
const rules = reactive<FormRules<MembershipForm>>({
  userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
  mobileNumber: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
  smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
  gradeId: [
    {
      required: true,
      message: '请选择学段',
      trigger: 'change'
    }
  ],
  subjectId: [
    {
      required: true,
      message: '请选择学科',
      trigger: 'change'
    }
  ],
  company: [{ required: true, message: '请输入单位名称', trigger: 'blur' }]
})
const verificationBtnStatus = ref(true)

const innerVisible = ref(false)
const imgCodeUrl = ref('')
const userImgCode = ref('')
//学段
const phaseList = ref<any[]>([])

//学科
const subjectList = ref<any[]>([])
const qrcodeImg = ref('')
const key = ref('')
const router = useRouter()
let intervalId = null // 用于存储定时器的ID
let privacyInterval = null // 用于存储隐私协议定时器的ID

//验证码倒计时
const btnCountDown = ref(60)
const isShowSmsCodeBtn = ref(true)
let btnTimer = null

const isClickSubmit = ref(false)

let moduleDataList = moduleList
let selectModuleData = ref<any>(moduleDataList[0])

const feedbackForm = ref<any>({
  moduleName: '教案生成',
  questions: [],
  detailedQuestion: '',
  phone: ''
})
const isCheckForm = ref(false)

watch(
  () => membershipForm.value.mobileNumber,
  (newValue, oldValue) => {
    console.log(newValue, oldValue)
    const reg = /^1[3456789]\d{9}$/
    verificationBtnStatus.value = !(
      membershipForm.value.mobileNumber && reg.test(membershipForm.value.mobileNumber.toString())
    )
  }
)

onBeforeUnmount(() => {
  // emitter.off('show-appdialog')
})
// 组件挂载时开始调用
onMounted(() => {
  if (def.dialogType == 'login' && def.dialogVisible) {
    getQrcode()
    privacyInterval = setInterval(checkIsShowAgreeStatus, 1000)
  }
  if (def.dialogType == 'experience') {
    getSubjectList()
    membershipForm.value = {
      userName: '',
      mobileNumber: null,
      smsCode: '',
      gradeId: null,
      subjectId: null,
      company: '',
      type: ''
    }
  }

  // emitter.on('show-appdialog', (data: any) => {
  //   console.log(data)
  //   if (data.dialogType == 'login') {
  //     loginDialogVisible.value = data.show
  //     console.log('show', data)
  //     if (data.show == false) {
  //       return
  //     } else {
  //       getQrcode()
  //       privacyInterval = setInterval(checkIsShowAgreeStatus, 1000)
  //     }
  //   }
  //   if (data.dialogType == 'experience') {
  //     defaultDialogVisible.value = data.show
  //     getSubjectList()
  //     membershipForm.value = {
  //       userName: '',
  //       mobileNumber: null,
  //       smsCode: '',
  //       gradeId: null,
  //       subjectId: null,
  //       company: '',
  //       type: ''
  //     }
  //   }
  //   if (data.dialogType == 'ppt') {
  //     defaultDialogVisible.value = data.show
  //   }
  //   if (data.dialogType == 'feedback') {
  //     feedbackDialogVisible.value = data.show
  //   }
  // })
})
// 组件卸载时清除定时器
onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId)
  }
  if (privacyInterval) {
    clearInterval(privacyInterval)
  }
  if (btnTimer) {
    clearInterval(btnTimer)
  }
})

const submitMembershipForm = async (formEl: FormInstance | undefined) => {
  console.log(formEl)
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    console.log(membershipForm.value, '>>>valid>>', formEl)
    if (valid) {
      defaultDialogVisible.value = false
      $emit('submitMembershipForm', membershipForm.value)
    } else {
      console.log('error submit!', fields)
      ElMessage.error('必填项还没完成呢，请检查一下')
    }
  })
}

function getVerificationCode() {
  verificationBtnStatus.value = true
  userImgCode.value = ''
  HTTP.getLoginQrCodeStrBase64()
    .then((res) => {
      console.log(res)
      if (res.code == 200) {
        console.log(res)
        innerVisible.value = true
        imgCodeUrl.value = 'data:image/png;base64,' + res.data
        verificationBtnStatus.value = false
      }
    })
    .catch((error) => {
      console.error('接口调用出错:', error)
      verificationBtnStatus.value = false
    })
}

function getSmsCode() {
  if (userImgCode.value.length == 0) {
    ElMessage.error('请输入图形验证码')
    return
  }
  let smsData = {
    mobileNumber: membershipForm.value.mobileNumber,
    imgCode: userImgCode.value
  }
  HTTP.getLoginQrCodeSMS(smsData as any)
    .then((res) => {
      console.log(res)
      if (res.code == 200) {
        console.log(res)
        innerVisible.value = false
        countDown()
        ElMessage.success('短信验证码已发送，请注意查收')
      }
    })
    .catch((error) => {
      console.error('接口调用出错:', error)
      getVerificationCode()
    })
}

function countDown() {
  btnCountDown.value = 60
  isShowSmsCodeBtn.value = false
  btnTimer = setInterval(() => {
    btnCountDown.value--
    if (btnCountDown.value < 0) {
      resetBtn()
    }
  }, 1000)
}

function resetBtn() {
  clearInterval(btnTimer)
  isShowSmsCodeBtn.value = true
}

function closeDialog() {
  console.log(1111)
  console.log(intervalId)
  if (intervalId) {
    clearTimeout(intervalId)
    intervalId = null
  }
  console.log(intervalId)
  defaultDialogVisible.value = false
  loginDialogVisible.value = false
  feedbackDialogVisible.value = false
  $emit('closeDialog', def.dialogType)
}

function getSubjectList() {
  HTTP.getSubject().then((res) => {
    console.log(res)
    phaseList.value = res.data
  })
}
// 监听学段的值改变调用的函数
watch(
  () => membershipForm.value.gradeId,
  (newValue, oldValue) => {
    console.log(phaseList.value)
    for (let item in phaseList.value) {
      // if(newValue){
      subjectList.value = []
      membershipForm.value.subjectId = null
      console.log('11111', phaseList.value[item].id)
      console.log('22222', membershipForm.value.gradeId)
      if (phaseList.value[item].id == membershipForm.value.gradeId) {
        subjectList.value = phaseList.value[item].children
        console.log('>phaseList.value[item]>>>>', phaseList.value)
        return
      }
      console.log(membershipForm.value)
      console.log(subjectList.value)
    }
  }
)



async function submitTemplate() {
  await Utill.checkRemaining()
  console.log('剩余次数', Data.userInfo.value?.remaining)

  if (Data.userInfo.value?.lv == 0) {
    //新用户
    console.log('新用户总剩余次数', Data.lastCanCountByUser.value)
    if (Data.lastCanCountByUser.value == 0) {
      ElMessage({
        message: '您的使用次数已全部用完',
        type: 'warning',
        plain: true
      })
      let dialogData = {
        show: true,
        activityDialogType: 'activity-main',
        activityDialogTitle: "邀请活动"
      }
      console.log(dialogData)
      emitter.emit('show-actDialog', dialogData)
      return
    }
  } else {
    //非新用户
    console.log('今日剩余次数', Data.userInfo.value?.remaining)
    if (Data.userInfo.value?.remaining == 0) {
      ElMessage({
        message: '今日您的使用次数已用完',
        type: 'warning',
        plain: true
      })
      return
    }
  }

  $emit('submitClick', selePttData.value)
  isClickSubmit.value = false
  defaultDialogVisible.value = false
  emitter.emit('submit-pptTemplate', selePttData.value)
}

function switchTemplate(item: Muban) {
  selePttData.value = item
}

console.log(router)
console.log(def)

function getQrcode() {
  HTTP.getQrCode()
    .then((res) => {
      console.log(res)
      if (res.code == 200) {
        qrcodeImg.value = res.data.url
        key.value = res.data.key
        startCheckingLoginStatus()
      }
    })
    .catch((error) => {
      console.error('接口调用出错:', error)
    })
}

const startCheckingLoginStatus = async () => {
  if (intervalId !== null) {
    clearTimeout(intervalId)
  }
  if (loginDialogVisible.value == false) {
    console.log('startCheckingLoginStatus', 1)
    return
  }
  intervalId = setTimeout(async () => {
    try {
      const response = await HTTP.wxLoginStatus(key.value)
      if (response.code === 200 && response.data) {
        // 登录成功
        clearTimeout(intervalId)
        intervalId = null
        Data.userInfo.value = response.data
        Data.token.value = response.data.token
        Data.isLogin.value = true
        Data.lv.value = response.data.lv


        const currentDate = Utill.getCurrentDate()
        if (response.data.endTime != 0 && response.data.endTime != null) {
          Data.isVipExpiration.value = currentDate > response.data.endTime
        } else {
          Data.isVipExpiration.value = true
        }
        if (response.data.lv == 0) {
          console.log('新用户')
          Data.lastCanCountByUser.value = response.data.lastCanCountByUser
          console.log(Data.inviteOpenId.value)

          if (!Data.inviteOpenId.value) {
            let inviterBase64 = Utill.getQueryString('invite_code')
            if (inviterBase64) {
              let inviteOpenId = Base64.decode(inviterBase64)
              Data.inviteOpenId.value = inviteOpenId
            }
          }

          if (Data.inviteOpenId.value != '' && response.data.openId != Data.inviteOpenId.value) {
            getRelationshipBinding()
          }
        }
        // if (Data.inviteOpenId.value != '' && response.data.openId != Data.inviteOpenId.value) {
        //   getRelationshipBinding()
        // }
        // router.replace({
        //   path: route.path,
        //   query: {} // 清空查询参数，重定向到根路径
        // });
        // console.log('route.path>>>>', route.path)
        console.log(Data.userInfo.value.token)
        closeDialog()
        ElMessage.success('登录成功')
        localStorage.setItem('userInfoToken', response.data.token)
        localStorage.setItem('userInfo', JSON.stringify(response.data))
        router.push('application')
      } else {
        // 如果未登录成功，则2秒后再次检查
        startCheckingLoginStatus()
      }
    } catch (error) {
      console.error('接口调用出错:', error)
      if (error.code === 1001) {
        console.log('二维码失效，重新获取')
        getQrcode()
      }
      startCheckingLoginStatus()
    }
  }, 2000)
}

//绑定邀请关系
function getRelationshipBinding() {
  console.log(Data.isActivityOpen.value)
  if (Data.isActivityOpen.value) {
    // 1031 实物活动
    const data = {
      openid: Data.userInfo.value?.openid,
      inviter: Data.inviteOpenId.value
    }

    HTTP.getActInitData(data as any).then((res) => {
      console.log(res)
      if (res.code == 200) {
        console.log(res)
        if (res.descb) {
          ElMessage.success(res.descb)
        }

      }
    })
      .catch((error) => {
        console.error('接口调用出错:', error)
      })
    return
  } else {
    //日常拉新活动 1015拉新活动（已过期）
    HTTP.getRelationshipBinding(Data.inviteOpenId.value)
      .then((res) => {
        console.log(res)
        if (res.code == 200) {
          console.log(res)
          ElMessage.success('助力成功')
        }
      })
      .catch((error) => {
        console.error('接口调用出错:', error)
      })
  }

}
function gotoAgreeMentPage() {
  Data.isShowAgree.value = true
  // privacyInterval = setInterval(checkIsShowAgreeStatus, 1000);
  Utill.gotoAgreeMentPage()
}
function checkIsShowAgreeStatus() {
  if (!Data.isShowAgree.value) {
    clearInterval(privacyInterval)
  }
  console.log(Data.isShowAgree.value)
}

function switchModule(item: any) {
  selectModuleData.value = item
  feedbackForm.value.moduleName = item.name
  feedbackForm.value.questions = []
}

function selectQuestions(item: any) {
  console.log(item)
  if (feedbackForm.value.questions.includes(item.name)) {
    feedbackForm.value.questions = feedbackForm.value.questions.filter(
      (selectedItem) => selectedItem != item.name
    )
  } else {
    feedbackForm.value.questions.push(item.name)
  }
  console.log(feedbackForm.value.questions)
}

function submitFeedback() {
  isCheckForm.value = true
  if (feedbackForm.value.moduleName == '') {
    ElMessage.error('必填项还没完成呢，请检查一下')
    return
  }
  if (feedbackForm.value.questions.length == 0) {
    ElMessage.error('必填项还没完成呢，请检查一下')
    return
  }
  if (feedbackForm.value.questions.includes('其他') && feedbackForm.value.detailedQuestion == '') {
    ElMessage.error('必填项还没完成呢，请检查一下')
    return
  }
  console.log(feedbackForm.value)
  feedbackForm.value.questions = JSON.stringify(feedbackForm.value.questions)
  console.log(feedbackForm.value)
  HTTP.submitFeedback(feedbackForm.value as any).then((res) => {
    console.log(res)
    if (res.code == 200) {
      closeDialog()
      console.log(res)
      let dialogData = {
        show: true,
        tipDialogType: 'feedback',
        tipDialogTitle: '收到！'
      }
      isCheckForm.value = false
      console.log(dialogData)
      emitter.emit('show-tipDialog', dialogData)
    }
  })
}
</script>

<template>
  <div class="default-dialog" v-if="dialogType == 'ppt' || dialogType == 'experience'">
    <el-dialog v-model="defaultDialogVisible" :close-on-click-modal="false" :title="dialogTitle" :show-close="true"
      @close="closeDialog">
      <div class="dialog-body" v-if="dialogType == 'ppt'">
        <div class="content-left">
          <div class="left-img-small" :class="{ 'left-img-large': index == 0 }"
            v-for="(item, index) in selePttData.imgList.slice(0, 4)" :key="index">
            <img class="left-img" :src="item.imgUrl" alt="" />
          </div>
        </div>
        <div class="content-right">
          <div class="template-list">
            <div class="template-item" v-for="(item, index) in TemplateImgList"
              :class="{ 'select-template': item.id == selePttData.id }" :key="index" @click="switchTemplate(item)">
              <img v-show="item.id == selePttData.id" class="select-img" src="../../../assets/select-template.png"
                alt="" />
              <div class="item-img">
                <img class="small-img" :src="item.imgList[0].imgUrl" alt="" />
              </div>
            </div>
          </div>
          <div>
            <el-button color="#485CEC" class="submit-btn" @click="submitTemplate" :disabled="isClickSubmit">
              生成课件
            </el-button>
          </div>
        </div>
      </div>
      <div class="dialog-body-ex" v-if="dialogType == 'experience'">
        <div class="content-left">
          <img src="../../../assets/experience-dialog-tip.png" alt="" />
          <div class="tip-text">
            亲爱的老师，欢迎您体验小鸿助教。<br />
            在您进行体验之前，请务必仔细阅读以下须知：<br />
            1.
            在体验过程中，请严格履行信息安全责任，确保您输入的内容符合社会主义核心价值观。不得含有颠覆国家政权、推翻社会主义制度、煽动分裂国家、破坏国家统一、宣扬恐怖主义、极端主义、宣扬民族仇恨、民族歧视、暴力、淫秽色情、虚假内容等信息，也不得含有扰乱经济秩序和社会秩序、侵害他人合法利益的内容。<br />
            2.
            请您知悉，本服务提供的所有内容均由人工智能模型输出，因此可能存在错误或遗漏。我们并不保证输出内容的准确性、完整性和规范性，同时这些内容并不代表服务提供者的立场或观点。<br />
            3.
            为了确保账号安全、满足相关法律规定和监管要求，实现身份的真实性，我们需要收集您的真实姓名用于身份验证。由于产品当前属于体验测试阶段，为了合理控制体验用户的数量，提供最佳试用体验，我们会收集您所在单位的名称、姓名和手机号用于小鸿助教的初始服务。如您拒绝提供这些信息，可能导致无法开通授权，影响产品的体验试用，请您谨慎考虑后再选择是否提供。<br />
            上述信息属于您的个人信息，请您谨慎提供。如您不同意我们收集上述信息，您将无法参与小鸿助教的体验申请。
          </div>
        </div>
        <div class="content-right">
          <div class="form-ctr">
            <el-form :model="membershipForm" ref="ruleFormRef" :rules="rules">
              <el-form-item required class="form-item-long" prop="userName">
                <template v-slot:label>
                  <span style="z-index: 200">姓名</span>
                  <div class="item-shadow"></div>
                </template>
                <el-input v-model="membershipForm.userName" :placeholder="'请输入姓名'" type="text"
                  :validate-event="false" />
              </el-form-item>
              <el-form-item required class="form-item-long" prop="mobileNumber">
                <template v-slot:label>
                  <span style="z-index: 200">联系方式</span>
                  <div class="item-shadow middle-shadow"></div>
                </template>
                <el-input v-model="membershipForm.mobileNumber" :placeholder="'请输入联系方式'" :validate-event="false" />
              </el-form-item>
              <el-form-item required class="form-item-long" prop="smsCode">
                <template v-slot:label>
                  <span style="z-index: 200">验证码</span>
                  <div class="item-shadow small-shadow"></div>
                </template>
                <div class="verificationCode-ctr">
                  <el-input v-model="membershipForm.smsCode" style="max-width: 178px" placeholder="请输入验证码"
                    :validate-event="false"></el-input>
                  <el-button class="verificationCode-btn" :disabled="verificationBtnStatus" v-if="isShowSmsCodeBtn"
                    @click="getVerificationCode">获取验证码</el-button>
                  <div class="count-down" v-else>{{ btnCountDown }}s</div>
                </div>
              </el-form-item>
              <el-form-item required class="form-item" prop="gradeId" style="margin-right: 17px">
                <template v-slot:label>
                  <span style="z-index: 200">学段</span>
                  <div class="item-shadow"></div>
                </template>
                <el-select v-model="membershipForm.gradeId" placeholder="请选择学段" :validate-event="false"
                  :empty-values="[null, undefined]" :value-on-clear="null" clearable>
                  <el-option v-for="item in phaseList" :key="item.id" :label="item.dictName" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item required class="form-item" prop="subjectId">
                <template v-slot:label>
                  <span style="z-index: 200">学科</span>
                  <div class="item-shadow"></div>
                </template>
                <el-select v-model="membershipForm.subjectId" placeholder="请选择学科" :validate-event="false">
                  <el-option v-for="item in subjectList" :key="item.id" :label="item.dictName" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item required class="form-item-long" prop="company">
                <template v-slot:label>
                  <span style="z-index: 200">所属单位</span>
                  <div class="item-shadow middle-shadow"></div>
                </template>
                <el-input v-model="membershipForm.company" :placeholder="'请输入单位名称'" type="text"
                  :validate-event="false" />
              </el-form-item>
              <el-form-item class="form-item-btn">
                <div class="test-tip">
                  您提交申请即同意
                  <span class="footer-link" @click="gotoAgreeMentPage"> 服务协议</span>、
                  <span class="footer-link" @click="gotoAgreeMentPage">隐私政策</span>
                </div>
                <el-button color="#485CEC" class="submit-btn test-btn" @click="submitMembershipForm(ruleFormRef)">
                  提交申请
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div></div>
        </div>
        <div class="inner-dialog">
          <el-dialog v-model="innerVisible" width="400" style="border-radius: 16px" title="获取短信验证码" append-to-body>
            <div>
              <div class="imgCode-ctr">
                <span>图形验证码：</span>
                <el-input v-model="userImgCode" style="width: 178px" placeholder="请输入图形验证码"></el-input>
                <img :src="imgCodeUrl" class="img-code" alt="" @click="getVerificationCode" />
              </div>
            </div>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="innerVisible = false">取消</el-button>
                <el-button color="#485CEC" type="primary" @click="getSmsCode"> 确定 </el-button>
              </div>
            </template>
          </el-dialog>
        </div>
      </div>
    </el-dialog>
  </div>
  <div class="login-dialog" v-else-if="dialogType == 'login'">
    <el-dialog v-model="loginDialogVisible" :close-on-click-modal="false" title="" :show-close="true"
      @close="closeDialog">
      <div class="login-ctr">
        <div class="login-title">
          {{ '微信扫码一键登录' }}
        </div>
        <div class="weixin-ctr">
          <!--          <div class="login-tip">未注册的微信号将自动创建小鸿AI账号</div>-->
          <div class="qrcode-ctr">
            <img class="qrcode-img" :src="qrcodeImg" alt="" />
          </div>
        </div>
        <div class="login-footer">
          欢迎使用小鸿AI，您登录即同意
          <span class="footer-link" @click="gotoAgreeMentPage"> 服务协议</span>、
          <span class="footer-link" @click="gotoAgreeMentPage">隐私政策</span>
        </div>
      </div>
    </el-dialog>
  </div>
  <div class="feedback-dialog" v-else-if="dialogType == 'feedback'">
    <el-dialog v-model="feedbackDialogVisible" :close-on-click-modal="false" :title="dialogTitle" :show-close="true"
      @close="closeDialog">
      <div class="dialog-body-feedback">
        <div class="left-ctr">
          <div class="feedback-tip">
            为了更好地服务于您，我们诚挚邀请您抽出几分钟时间，与我们分享您的感受和建议。您的宝贵意见对我们至关重要，我们非常重视每一位用户的反馈。期待您的积极参与！立即开始吧！
          </div>
          <div class="feedback-item">
            <div class="item-title">
              <span class="important">* </span>
              <span class="title-name">您反馈的模块
                <span class="title-shadow"></span>
              </span>
              <span class="title-tip">（单选）</span>
            </div>
            <div class="item-content">
              <div class="content-radio" v-for="(item, index) in moduleDataList" :key="index"
                @click="switchModule(item)">
                <span class="radio-check" :class="{ 'select-radio': item.id == selectModuleData.id }">
                  <!--                  <span class="check-icon">-->
                  <!--                  </span>-->
                  <img class="check-icon" src="@/assets/radio-check-icon.png" alt="" />
                </span>
                <span class="radio-name" :class="{ 'short-name': item.name.length == 3 }">{{
    item.name
  }}</span>
              </div>
              <div class="error" v-show="isCheckForm && feedbackForm.moduleName == ''">
                请选择您反馈的模块
              </div>
            </div>
          </div>
          <div class="feedback-item">
            <div class="item-title">
              <span class="important">* </span>
              <span class="title-name">您遇到的问题
                <span class="title-shadow"></span>
              </span>
              <span class="title-tip">（多选）</span>
            </div>
            <div class="item-content">
              <div class="content-radio" v-for="(item, index) in selectModuleData.questions" :key="index"
                @click="selectQuestions(item)">
                <span class="check-box-check" :class="{ 'select-radio': feedbackForm.questions.includes(item.name) }">
                  <!--                  <span class="check-icon">-->
                  <!--                  </span>-->
                  <img class="check-icon" src="@/assets/radio-check-icon.png" alt="" />
                </span>
                <span class="radio-name">{{ item.name }}</span>
                <span class="radio-tip" v-show="feedbackForm.questions.includes('其他')">{{
    item.tip
  }}</span>
              </div>
              <div class="error" v-show="isCheckForm && feedbackForm.questions.length == 0">
                请选择您遇到的问题
              </div>
            </div>
          </div>
        </div>
        <div class="right-ctr">
          <div class="feedback-item">
            <div class="item-title">
              <span class="important" v-show="feedbackForm.questions.includes('其他')">* </span>

              <span class="title-name">问题与建议
                <span class="title-shadow"></span>
              </span>
            </div>
            <div class="item-content right-content">
              <el-input v-model="feedbackForm.detailedQuestion" show-word-limit style="width: 364px" :rows="2"
                type="textarea" placeholder="请留下您的宝贵建议！" />
              <div class="error" v-show="isCheckForm &&
    feedbackForm.questions.includes('其他') &&
    feedbackForm.detailedQuestion == ''
    ">
                请填写您的问题与建议
              </div>
            </div>
          </div>
          <div class="feedback-item">
            <div class="item-title">
              <span class="title-name">留下您的联系方式，我们会尽快与您联系
                <span class="title-shadow"></span>
              </span>
            </div>
            <div class="item-content right-content">
              <el-input v-model="feedbackForm.phone" style="width: 364px" placeholder="请留下您的联系方式" />
              <!--              <div class="error">-->
              <!--                请填写您的联系方式-->
              <!--              </div>-->
            </div>
          </div>
          <el-button color="#485CEC" class="feedback-btn" @click="submitFeedback()">
            提交反馈
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.default-dialog {
  :deep(.el-dialog) {
    width: 865px;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgba(158, 172, 229, 0.3);
    padding: 0;
    background: #f6f6fb;

    .el-dialog__header {
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
      align-items: center;
      font-variation-settings: 'wght' 600;
      color: #040a39;
      background: linear-gradient(90deg, #edf1ff 0%, rgba(213, 222, 252, 0.21) 100%);
      background: url('@/assets/longDialog-header-bg.png') no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 16px 16px 0 0;
      height: 58px;
      display: flex;
      padding: 0 32px;
    }

    .el-dialog__headerbtn {
      height: 58px;
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
    }
  }
}

.dialog-body {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 11px 18px 16px;
  padding: 14px 16px 21px;
  padding-right: 5px;
  background: #ffffff;
  border-radius: 12px;
}

.dialog-body-ex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 11px 18px 16px;
  border-radius: 12px;
}

.dialog-body-ex .content-left {
  width: 491px;
  height: 494px;
  border-radius: 12px;
  opacity: 1;
  background: #ffffff;
  padding: 12px;
}

.dialog-body-ex .content-right {
  width: 322px;
  background: #ffffff;
  border-radius: 12px;
  padding: 16px 16px 16px 17px;
}

.tip-text {
  padding: 4px;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  text-align: justify;
}

.form-ctr {
  :deep(.el-form) {
    display: flex;
    flex-wrap: wrap;

    .el-form-item__label {
      justify-content: flex-start;
      position: relative;
    }
  }

  :deep(.el-select) {
    width: 245px;
    margin-left: 6px;

    .el-select__wrapper {
      background: #f6f6fb;
      box-shadow: none;
      border-radius: 6px;
    }
  }

  :deep(.el-input) {
    width: 283px;
    margin-left: 6px;

    .el-input__wrapper {
      background: #f6f6fb;
      box-shadow: none;
      border-radius: 6px;
    }

    .el-input__count-inner {
      background: transparent !important;
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #485cec;
      background: #ffffff;
    }
  }
}

.form-item {
  flex-direction: column;
  width: 136px;
  margin-bottom: 11px !important;
}

.form-item-long {
  flex-direction: column;
  width: 100%;
  margin-bottom: 11px !important;
}

.form-item-btn {
  flex-direction: column;
  width: 100%;
  margin-bottom: 0 !important;
}

.item-shadow {
  border-radius: 62px 0 2px 0;
  opacity: 1;
  height: 5px;
  background: #dbdfff;
  width: 29px;
  bottom: 7px;
  position: absolute;
  left: 10px;
}

.small-shadow {
  width: 42px;
}

.middle-shadow {
  width: 55px;
}

.long-shadow {
  width: 118px;
}

.verificationCode-ctr {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.verificationCode-btn {
  width: 94px;
  height: 32px;
  border-radius: 69px;
  opacity: 1;

  box-sizing: border-box;
  border: 1px solid #485cec;
  font-size: 14px;
  font-weight: normal;
  color: #485cec;
}

.verificationCode-btn:hover {
  background: #eceefd;
  color: #485cec;
  border: 1px solid #485cec;
}

.count-down {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: #485cec;
  width: 94px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-tip {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  color: #909399;
  margin-left: 39px;
  margin-top: 17px;
}

.img-code {
  width: 100px;
  height: auto;
}

.imgCode-ctr {
  display: flex;
  line-height: 32px;
}

.content-left {
  width: 491px;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}

.left-img-small {
  width: 159px;
  height: 96px;
  border-radius: 6px;
}

.left-img-large {
  width: 491px;
  height: 278px;
  border-radius: 6px;
}

.left-img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #efefef;
}

.content-right {
  //width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
}

.template-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  height: 339px;
  overflow-y: auto;
  scrollbar-width: auto;
}

.template-list::-webkit-scrollbar {
  display: block !important;
}

.template-list::-webkit-scrollbar-button {
  display: block !important;
}

.template-list::-webkit-scrollbar {
  width: 6px;
}


.template-item {
  width: 142px;
  height: 80px;
  border-radius: 8px;
  position: relative;
}

.select-img {
  width: 142px;
  height: 80px;
  position: absolute;
  z-index: 10;
}

.item-img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  z-index: 100;
}

.small-img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.submit-btn {
  margin-top: 9px;
  width: 290px !important;
  height: 40px;
  border-radius: 69px;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}

.test-btn {
  margin-top: 12px;
}

.dialog-footer {
  :deep(.el-button) {
    width: 60px;
    height: 32px;
    border-radius: 92px;
    font-size: 14px;
    font-weight: 500;
  }
}

.login-dialog {
  :deep(.el-dialog) {
    width: 458px;
    height: 459px;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgba(158, 172, 229, 0.3);
    padding: 18px;
    background: #f6f6fb;

    .el-dialog__body {
      background: #ffffff;
      width: 422px;
      height: 423px;
      border-radius: 12px;
    }

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__headerbtn {
      right: 21px;
      top: 18px;
    }
  }
}

.login-ctr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 53px;
}

.login-title {
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  color: #040a39;
  margin-bottom: 32px;
}

.weixin-ctr {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-ctr {
  width: 192px;
  height: 192px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.qrcode-img {
  border-radius: 12px;
}

.login-tip {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0;
  margin-top: 12px;
  margin-bottom: 27px;
  color: #909399;
}

.login-footer {
  width: 100%;
  text-align: center;
  margin-top: 32px;
  font-size: 12px;
  color: #909399;
}

.footer-link {
  color: #485cec;
}

.footer-link:hover {
  cursor: pointer;
}

.feedback-dialog {
  :deep(.el-dialog) {
    width: 865px;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgba(158, 172, 229, 0.3);
    padding: 0;
    background: #f6f6fb;

    .el-dialog__header {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      align-items: center;
      color: #040a39;
      background: linear-gradient(90deg, #edf1ff 0%, rgba(213, 222, 252, 0.21) 100%);
      background: url('@/assets/longDialog-header-bg.png') no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 16px 16px 0 0;
      height: 50px;
      display: flex;
      padding: 0 32px;
    }

    .el-dialog__headerbtn {
      height: 50px;
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
    }
  }

  :deep(.el-textarea) {
    width: 364px;
    height: 152px;

    .el-textarea__inner {
      background: #f6f6fb;
      box-shadow: none;
      height: 152px;
      border-radius: 6px;
    }

    .el-input__count {
      background: transparent !important;
    }

    .el-textarea__inner.is-focus {
      border: 1px solid #485cec;
      background: #ffffff;
    }
  }

  :deep(.el-input) {
    width: 364px;

    .el-input__wrapper {
      background: #f6f6fb;
      box-shadow: none;
      border-radius: 6px;
    }

    .el-input__count-inner {
      background: transparent !important;
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #485cec;
      background: #ffffff;
    }
  }
}

.dialog-body-feedback {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 526px;
  padding: 16px 17px 16px 16px;
}

.left-ctr {
  width: 408px;
  height: 494px;
  background: #ffffff;
  border-radius: 12px;
}

.right-ctr {
  width: 408px;
  height: 494px;
  background: #ffffff;
  border-radius: 12px;
  padding: 16px 6px;
  position: relative;
}

.feedback-tip {
  width: 384px;
  height: 88px;
  border-radius: 8px;
  opacity: 1;
  background: rgba(72, 92, 236, 0.08);
  margin: 12px 12px 20px;
  padding: 8px;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  text-align: justify;
  color: #040a39;
}

.feedback-item {
  margin-left: 16px;
}

.item-title {
  font-size: 14px;
  font-weight: 350;
  line-height: normal;
  color: #4e5969;
}

.important {
  color: #fd1c1c;
}

.title-name {
  position: relative;
  z-index: 100;
}

.title-shadow {
  border-radius: 62px 0 2px 0;
  opacity: 1;
  height: 5px;
  background: #dbdfff;
  width: 100%;
  bottom: 0;
  position: absolute;
  left: -2px;
  z-index: -1;
}

.title-tip {
  color: #b1b3b8;
}

.item-content {
  display: flex;
  flex-wrap: wrap;
  /*gap: 12px 25px;*/
  margin-top: 11px;
  margin-left: 9px;
  margin-bottom: 21px;
  position: relative;
}

.right-content {
  margin-left: 0;
  margin-top: 12px;
}

.content-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 22px;
  margin-right: 25px;
  margin-bottom: 12px;
}

.radio-check {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #c9cdd4;
  margin-right: 8px;
  position: relative;
}

.check-box-check {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #c9cdd4;
  margin-right: 8px;
  border-radius: 2px;
  position: relative;
}

.select-radio {
  color: #485cec;
  border: 1px solid #485cec;
  background-color: #485cec;
}

/*.check-icon{
  color: #fff;
  background: url('@/assets/radio-check-icon.png') no-repeat center center;
  background-size: 55% 55%;
  display: inline-block;
  width: 100%;
  height: 100%;
}*/
.check-icon {
  width: 10px;
  height: 8px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.radio-name {
  line-height: 1;
}

.short-name {
  margin-right: 14px;
}

.radio-tip {
  color: #b1b3b8;
}

.feedback-btn {
  width: 364px;
  height: 40px;
  border-radius: 69px;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  margin-left: 16px;
  position: absolute;
  bottom: 16px;
}

.error {
  color: #f56c6c;
  position: absolute;
  bottom: -20px;
  font-size: 12px;
}
</style>

<style>
.custom-loading-svg .el-loading-spinner {
  height: 200px;
  width: 200px;
  background-repeat: no-repeat;
  background-image: url(/src/assets/loading.gif);
  background-size: 200px 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-loading-svg .el-loading-spinner .circular {
  width: 92px;
  height: 72px;
  display: none;
}
</style>
