import type { Slide } from '@/types/slides'

export const slides: Slide[] = [
  {
    id: 'shouye',
    elements: [
    ],
  },
 
]

