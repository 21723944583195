<script setup lang="ts">
import { reactive, ref, watch, onMounted, onUnmounted, onBeforeUnmount } from 'vue'
import { Data } from '@/stores/data'

let def = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  dialogType: {
    type: String,
    default: ''
  },
  dialogTitle: {
    type: String,
    default: ''
  }
})
const tipDialogVisible = ref(def.dialogVisible)

const $emit = defineEmits(['submitClick', 'closeDialog'])
console.log(def)
function closeDialog() {
  tipDialogVisible.value = false
  $emit('closeDialog', def.dialogType)
}
</script>

<template>
  <div
    class="tip-dialog"
    v-if="dialogType != 'essayCorrection' && dialogType != 'mentalCalculation' && dialogType != 'officialAccount' "
  >
    <el-dialog
      v-model="tipDialogVisible"
      :close-on-click-modal="false"
      :title="dialogTitle"
      :show-close="true"
      @close="closeDialog"
    >
      <div class="dialog-body">
        <div v-if="dialogType == 'receive'">
          <img class="receive-img" src="@/assets/receive-xh-img.png" alt="" />
          <div class="receive-content">已收到您的申请！</div>
          <div class="receive-tip">
            我们将会在<span class="time-bold">3个工作日</span>内短信通知您审核结果
          </div>
          <el-button color="#485CEC" class="submit-btn" @click="closeDialog"> 我知道了 </el-button>
        </div>
        <div v-else-if="dialogType == 'feedback'">
          <img class="receive-img" src="@/assets/receive-xh-img.png" alt="" />
          <div class="receive-content">已收到您的反馈！</div>
          <div class="receive-tip">我们将会根据您的建议，深刻反省，积极改正！</div>
          <el-button color="#485CEC" class="submit-btn" @click="closeDialog"> 我知道了 </el-button>
        </div>
        <div v-else-if="dialogType == 'get'">
          <img class="receive-img membership-img" src="@/assets/membership-xh-img.png" alt="" />
          <div class="membership-content">恭喜您已获得体验资格</div>
          <div class="membership-tip">小鸿送您<span class="time-bold">1个月</span>的使用权限</div>
          <el-button color="#485CEC" class="submit-btn" @click="closeDialog">收下</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
  <div class="wxQrcode-dialog" v-else>
    <el-dialog
      v-model="tipDialogVisible"
      :close-on-click-modal="false"
      :title="dialogTitle"
      :show-close="true"
      @close="closeDialog"
    >
      <div class="wx-dialog-body">
        <div class="qrcode-ctr" v-if="dialogType != 'officialAccount'">
          <img
            v-show="dialogType == 'essayCorrection'"
            class="qrcode-img"
            :src="Data.qrCodeList.value.pigai"
            alt=""
          />
          <img
            v-show="dialogType == 'mentalCalculation'"
            class="qrcode-img"
            :src="Data.qrCodeList.value.kousuan"
            alt=""
          />

        </div>
        <div class="gzh-qrcode-ctr" v-else>
          <img
            v-show="dialogType == 'officialAccount'"
            class="gzh-qrcode-img"
            src="@/assets/activity/gzh-img.jpg"
            alt=""
          />
        </div>
        <div v-if="dialogType == 'officialAccount'">
          <div class="content-title">微信扫码关注小鸿公众号</div>
        </div>
        <div v-else>
          <div class="content-title">微信扫码使用“{{ dialogTitle }}”</div>
          <div class="content-tip">
            {{ dialogType == 'essayCorrection' ? '一键批改语文、英语作文' : '一键拍照，秒出对错' }}
          </div>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.tip-dialog {
  :deep(.el-dialog) {
    width: 355px;
    height: 508px;
    border-radius: 12px;
    padding: 0;
    background: #f6f6fb;
    .el-dialog__header {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      align-items: center;
      font-variation-settings: 'wght' 600;
      color: #040a39;
      background: linear-gradient(90deg, #edf1ff 0%, rgba(213, 222, 252, 0.21) 100%);
      background: url('@/assets/dialog-header-bg.png') no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 16px 16px 0 0;
      height: 58px;
      display: flex;
      padding: 0 32px;
    }
    .el-dialog__headerbtn {
      height: 58px;
    }
    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
    }
  }
}
.dialog-body {
  width: 322px;
  height: 423px;
  background: #ffffff;
  border-radius: 12px;
  margin: 13px 15px 14px 18px;
}
.wx-dialog-body {
  width: 322px;
  height: 346px;
  background: #ffffff;
  border-radius: 12px;
  margin: 13px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.receive-img {
  margin-top: 24px;
  margin-left: 77px;
  width: 156px;
  height: 159px;
}
.membership-img {
  margin-top: 36px;
  margin-left: 91px;
  width: 137px;
  height: 120px;
}
.receive-content {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: justify; /* 浏览器可能不支持 */
  letter-spacing: 0;
  color: #040a39;
  margin-top: 22px;
  margin-left: 73px;
}
.membership-content {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: justify; /* 浏览器可能不支持 */
  letter-spacing: 0;
  color: #040a39;
  margin-top: 49px;
  margin-left: 41px;
}
.receive-tip {
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
  margin-left: 24px;
  margin-top: 19px;
  line-height: 24px;
}
.membership-tip {
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
  margin-left: 82px;
  margin-top: 19px;
  line-height: 24px;
}
.time-bold {
  font-weight: 600;
  font-size: 14px;
  color: #485cec;
}
.submit-btn {
  margin-left: 16px;
  margin-bottom: 21px;
  margin-top: 90px;
  width: 290px !important;
  height: 40px;
  border-radius: 69px;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}
.wxQrcode-dialog {
  :deep(.el-dialog) {
    width: 354px;
    height: 433px;
    border-radius: 12px;
    padding: 0;
    background: #f6f6fb;
    .el-dialog__header {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      align-items: center;
      font-variation-settings: 'wght' 600;
      color: #040a39;
      background: linear-gradient(90deg, #edf1ff 0%, rgba(213, 222, 252, 0.21) 100%);
      background: url('@/assets/dialog-header-bg.png') no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 16px 16px 0 0;
      height: 58px;
      display: flex;
      padding: 0 32px;
    }
    .el-dialog__headerbtn {
      height: 58px;
    }
    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
    }
  }
}
.qrcode-ctr {
  width: 160px;
  height: 160px;
  margin-top: 50px;
}
.gzh-qrcode-ctr {
  width: 169px;
  height: 164px;
  margin-top: 48px;
  background: #EAF0FC;
  padding: 11px 14px;
  border-radius: 4px;
}
.gzh-qrcode-img {
  width: 140px;
  height: 140px;
}
.content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: justify; /* 浏览器可能不支持 */
  color: #040a39;
  margin-top: 27px;
  margin-bottom: 16px;
  font-family: Arial, sans-serif;
}
.content-tip {
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  color: #040a39;
  font-family: Arial, sans-serif;
}
</style>
