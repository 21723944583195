export const networkTip = {
  errText: '用户量太大，小鸿忙不过来了，请重试~',
  nullText: '请把问题描述的更详细点哦'
}

export const jiaoanFilterKey = ['教案', '教学设计', '课程设计']
export const modeTitle ={
  lwss:'参考联网搜索结果回答',
  wdwd:'参考文档内容回答',
 
}
export const resourceTypeText = {
  lesson: '教案',
  courseware: '课件',
  study: '学案',
  activityPlanning: '校园活动策划',
  speechDraft: '演讲稿',
  readingNotes:'读书笔记',
  workPlan:'工作计划',
  workLog:'教学工作日志',
}

export const applicationList = [
  {
    id: 1,
    name: '智能备课',
    icon: 'icons/beike-icon.png',
    iconClass: 'title-icon',
    activeClass: 'tab1-selected',
    applicationItem: [
      {
        id: 'lesson',
        name: '教案生成',
        icon: 'icons/beike-1.png',
        info: '我的教案编写提效工具',
        resourceType: 'lesson',
        bgImg:'icons/lesson-img.png',
        tipImg:'icons/lesson-tip.png',
        status: true
      },
      {
        id: 'courseware',
        name: '课件生成',
        icon: 'icons/beike-2.png',
        info: '让课件制作变得轻松简单',
        resourceType: 'courseware',
        bgImg:'icons/courseware-img.png',
        tipImg:'icons/courseware-tip.png',
        status: true
      }
      // {
      //   id: 'teachingPlain',
      //   name: '微课生成',
      //   icon: 'icons/beike-3.png',
      //   info: '我的教案编写提效工具',
      //   resourceType: 'temp1',
      //   status:false,
      // },
      // {
      //   id: 'teachingPlain',
      //   name: '学案生成',
      //   icon: 'icons/beike-4.png',
      //   info: '我的教案编写提效工具',
      //   resourceType: 'temp2',
      //   status:false,
      // }
    ]
  },
  {
    id: 2,
    name: '作业·批改',
    icon: 'icons/homework-icon.png',
    iconClass: 'hw-icon',
    activeClass: 'tab2-selected',
    applicationItem: [
      {
        id: 'essayCorrection',
        name: '作文批改',
        icon: 'icons/homework-3.png',
        info: '一键批改语文、英语作文',
        resourceType: 'essayCorrection',
        status: true
      },
      {
        id: 'mentalCalculation',
        name: '口算批改',
        icon: 'icons/homework-4.png',
        info: '一键拍照，秒出对错',
        resourceType: 'mentalCalculation',
        status: true
      },
      {
        id: 'teachingPlain',
        name: '作业生成',
        icon: 'icons/homework-1.png',
        info: '快速制作符合班级学情的作业',
        resourceType: 'temp3',
        status: false
      },
      {
        id: 'teachingPlain',
        name: '试卷生成',
        icon: 'icons/homework-2.png',
        info: '快速制作符合班级学情的试卷',
        resourceType: 'temp4',
        status: false
      }
    ]
  },
  {
    id: 3,
    name: '工作事务',
    icon: 'icons/work-icon.png',
    iconClass: 'work-icon',
    activeClass: 'tab3-selected',
    applicationItem: [
      {
        id: 'activityPlanning',
        name: '校园活动策划',
        icon: 'icons/work-2.png',
        info: '小鸿助您高效完成策划方案',
        resourceType: 'activityPlanning',
        status: true
      },
      {
        id: 'speechDraft',
        name: '演讲稿',
        icon: 'icons/work-3.png',
        info: '看小鸿如何1秒生成演讲稿',
        resourceType: 'speechDraft',
        status: true
      },
      {
        id: 'readingNotes',
        name: '读书笔记',
        icon: 'icons/work-6.png',
        info: '小鸿帮您完成读书任务',
        resourceType: 'readingNotes',
        status: true
      },
      {
        id: 'workPlan',
        name: '教研工作计划',
        icon: 'icons/work-7.png',
        info: '小鸿协助您完成工作计划',
        resourceType: 'workPlan',
        status: true
      },
      {
        id: 'workLog',
        name: '教学工作日志',
        icon: 'icons/work-8.png',
        info: '教学感想交给小鸿来生成',
        resourceType: 'workLog',
        status: true
      },
      {
        id: 'teachingPlain',
        name: '文档助手',
        icon: 'icons/work-5.png',
        info: '文档总结/内容摘要/提问回答',
        resourceType: 'temp11',
        status:false,
      },
      {
        id: 'teachingPlain',
        name: '班级/家长群通知',
        icon: 'icons/work-4.png',
        info: '看小鸿如何规范的发通知',
        resourceType: 'temp10',
        status: false
      },
      {
        id: 'teachingPlain',
        name: '教师评语',
        icon: 'icons/work-1.png',
        info: '根据性格给每个学生写评语',
        resourceType: 'temp7',
        status: false
      }
      // {
      //   id: 'teachingPlain',
      //   name: '中英互译',
      //   icon: 'icons/work-6.png',
      //   info: '我的教案编写提效工具',
      //   resourceType: 'temp12',
      //   status:false,
      // }
    ]
  }
]

export const moduleList = [
  {
    id: 1,
    type: 'lesson',
    name: '教案生成',
    questions: [
      {
        id: 1,
        name: '生成的教案与教学内容不符'
      },
      {
        id: 2,
        name: '操作体验不佳'
      },
      {
        id: 3,
        name: '回答的内容有误'
      },
      {
        id: 4,
        name: '图片生成效果不佳'
      },
      {
        id: 5,
        name: '文档内容丢失'
      },
      {
        id: 6,
        name: '其他',
        tip: '（具体描述可写在“问题与建议”中）'
      }
    ]
  },
  {
    id: 2,
    type: 'courseware',
    name: '课件生成',
    questions: [
      {
        id: 1,
        name: '生成的课件与教学内容不符'
      },
      {
        id: 2,
        name: '操作体验不佳'
      },
      {
        id: 3,
        name: '回答的内容有误'
      },
      {
        id: 4,
        name: '图片生成效果不佳'
      },
      {
        id: 5,
        name: '文档内容丢失'
      },
      {
        id: 6,
        name: '其他',
        tip: '（具体描述可写在“问题与建议”中）'
      }
    ]
  },
  {
    id: 3,
    type: 'activityPlanning',
    name: '校园活动策划',
    questions: [
      {
        id: 1,
        name: '生成的校园活动策划案不可用'
      },
      {
        id: 2,
        name: '操作体验不佳'
      },
      {
        id: 3,
        name: '回答的内容有误'
      },
      {
        id: 4,
        name: '图片生成效果不佳'
      },
      {
        id: 5,
        name: '文档内容丢失'
      },
      {
        id: 6,
        name: '其他',
        tip: '（具体描述可写在“问题与建议”中）'
      }
    ]
  },
  {
    id: 4,
    type: 'speechDraft',
    name: '演讲稿',
    questions: [
      {
        id: 1,
        name: '生成的演讲稿不可用'
      },
      {
        id: 2,
        name: '操作体验不佳'
      },
      {
        id: 3,
        name: '回答的内容有误'
      },
      {
        id: 4,
        name: '图片生成效果不佳'
      },
      {
        id: 5,
        name: '文档内容丢失'
      },
      {
        id: 6,
        name: '其他',
        tip: '（具体描述可写在“问题与建议”中）'
      }
    ]
  },
  {
    id: 5,
    type: 'readingNotes',
    name: '读书笔记',
    questions: [
      {
        id: 1,
        name: '生成的读书笔记不可用'
      },
      {
        id: 2,
        name: '操作体验不佳'
      },
      {
        id: 3,
        name: '回答的内容有误'
      },
      {
        id: 4,
        name: '图片生成效果不佳'
      },
      {
        id: 5,
        name: '文档内容丢失'
      },
      {
        id: 6,
        name: '其他',
        tip: '（具体描述可写在“问题与建议”中）'
      }
    ]
  },
  {
    id: 6,
    type: 'workPlan',
    name: '教研工作计划',
    questions: [
      {
        id: 1,
        name: '生成的教研工作计划不可用'
      },
      {
        id: 2,
        name: '操作体验不佳'
      },
      {
        id: 3,
        name: '回答的内容有误'
      },
      {
        id: 4,
        name: '图片生成效果不佳'
      },
      {
        id: 5,
        name: '文档内容丢失'
      },
      {
        id: 6,
        name: '其他',
        tip: '（具体描述可写在“问题与建议”中）'
      }
    ]
  },
  {
    id: 7,
    type: 'workLog',
    name: '教学工作日志',
    questions: [
      {
        id: 1,
        name: '生成的教学工作日志不可用'
      },
      {
        id: 2,
        name: '操作体验不佳'
      },
      {
        id: 3,
        name: '回答的内容有误'
      },
      {
        id: 4,
        name: '图片生成效果不佳'
      },
      {
        id: 5,
        name: '文档内容丢失'
      },
      {
        id: 6,
        name: '其他',
        tip: '（具体描述可写在“问题与建议”中）'
      }
    ]
  },
  {
    id: 8,
    type: 'myFile',
    name: '我的文档',
    questions: [
      {
        id: 1,
        name: '展示混乱'
      },
      {
        id: 2,
        name: '操作体验不佳'
      },
      {
        id: 6,
        name: '其他',
        tip: '（具体描述可写在“问题与建议”中）'
      }
    ]
  }
]
