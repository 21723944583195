import axios, { type AxiosInstance, type AxiosRequestConfig ,CancelTokenSource} from 'axios'
import { ElementTip } from '../../stores/ElementTip'
import { Data } from '@/stores/data'
import { Utill } from '@/stores/Utill'
/**
 * http网络请求基类
 */
export class BaseRequest {
  protected instance: AxiosInstance
  protected config: AxiosRequestConfig

  constructor(config?: AxiosRequestConfig) {
    this.config = config || {}
    this.instance = axios.create(config)
    this.setRequestInterceptor()
    this.setResponseInterceptor()
  }

  /**
   * 设置请求拦截器
   */
  protected setRequestInterceptor() {
    this.instance.interceptors.request.use(
      config => {
        // 在请求中添加 Token
        const token =  Data.token.value?Data.token.value : localStorage.getItem('userInfoToken');
        if (token) {
          config.headers.token = `${token}`;
        }
        console.log('请求,参数-->', config)
        return config;
      },
      // (opts) => {
      //   console.log('请求,参数-->', opts)
      //   return opts
      // },
      (err) => {
        console.log('请求,错误-->', err)
        return Promise.reject(err)
      }
    )
  }

  /**
   * 设置响应拦截器
   */
  protected setResponseInterceptor() {
    this.instance.interceptors.response.use(
      (response) => {
        console.log('请求,返回-->', response)
        if (response.data.code == 200||response.data.errno==0) {
          return response.data
        }else if(response.data.code == 1001) {
          const errTip = response.data.msg ? response.data.msg : '二维码已失效，请重新扫描二维码'
          console.log(errTip)
          ElementTip.errorTip(errTip)
          return Promise.reject(response.data)
        }else if(response.data.code == 1002) {
          const errTip = response.data.msg ? response.data.msg : '网络错误,请稍候再试'
          console.log(errTip)
          return Promise.reject(response.data)
        }else if(response.data.code == 401) {
          const errTip = '登录超时，请重新登录'
          // ElementTip.errorTip(errTip)
          Utill.outLoading();
          return Promise.reject(response.data)
        } else {
          const errTip = response.data.msg ? response.data.msg : '网络错误,请稍候再试'
          if (response.data.msg) {
            ElementTip.errorTip(errTip)
          }

          return Promise.reject(response.data)
        }
      },
      (err) => {
        if (axios.isCancel(err)){

        }else{
          // ElementTip.errorTip('网络错误,请稍候再试')
          
          return Promise.reject(err)
        }
        console.log('请求失败,返回-->', err)
      }
    )
  }

  // 用于存储每个请求的 CancelTokenSource
  private cancelSources: Map<string, CancelTokenSource> = new Map();

  // 添加一个新的请求时存储其 CancelTokenSource
  private addCancelSource(config: AxiosRequestConfig, source: CancelTokenSource) {
    // 你可以使用 config 中的 url 或其他唯一标识符作为 key
    // 这里假设每个请求都有一个唯一的 id
    if (config.url && !this.cancelSources.has(config.url)) {
      this.cancelSources.set(config.url, source);
    }
  }
   // 取消请求的方法
   cancelRequest(url: string) {
    const source = this.cancelSources.get(url);
    if (source) {
      source.cancel('Request cancelled by the user.');
      this.cancelSources.delete(url);
    }
  }
  // request<T = any>(config: AxiosRequestConfig): Promise<T> {
  //   return this.instance(config)
  // }
   // 更新 request 方法以支持取消
   request<T = any>(config: AxiosRequestConfig & { id?: string }): Promise<T> {
    // 创建一个 CancelTokenSource
    const source = axios.CancelToken.source();

    // 如果请求配置中提供了 id，则使用它作为 key 存储 CancelTokenSource
    if (config.id) {
      this.addCancelSource(config, source);
    }

    // 将 token 添加到请求配置中
    config.cancelToken = source.token;

    // 发送请求
    return this.instance(config)
  }
}

// 更改请求
const dizUrl = window.location.origin
const baseURL1 = (dizUrl&&import.meta.env.MODE == 'production') ?dizUrl+'/' : import.meta.env.VITE_BASE_URL
// console.log( import.meta.env.MODE == 'production',">>>baseURL1>>>",baseURL1)
//消息初始数据
const badgeConfig: AxiosRequestConfig = {
  baseURL: baseURL1 + 'api/pc',
  method: 'POST',
  timeout: 15000,
  withCredentials: false
}
const baseURL2 = (dizUrl&&import.meta.env.MODE == 'production') ?dizUrl+'/api/pc/newapipc/' : import.meta.env.VITE_PHP_URL
const phpBadgeConfig: AxiosRequestConfig = {
  baseURL: baseURL2 ,
  // baseURL: baseURL1,
  method: 'POST',
  timeout: 15000,
  withCredentials: false
}

// 创建全局的Axios实例
export const axiosApi: BaseRequest = new BaseRequest(badgeConfig)

export const phpAxiosApi: BaseRequest = new BaseRequest(phpBadgeConfig)
// axiosApi.cancelRequest(url)