<template>
  <!-- viewer.properties: 该文件为PDF插件的中文版配置，放置本地或使用服务器网络地址 -->
  <link rel="resource" type="application/l10n" href="pdf/viewer.properties" />
  <VuePdfApp :page-scale="pageScale" :theme="theme" :style="`width: ${viewerWidth}; height: ${viewerHeight};`"
    :pdf="src" :file-name="fileName" v-bind="$attrs" @pages-rendered="pagesRendered" :config="config">
    <template #toolbar-right-append>
      <div class="viewer-prepend">
        <div class="closeBtn" @click="closePdfHandler">关闭</div>
      </div>
    </template>
  </VuePdfApp>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import VuePdfApp from 'vue3-pdf-app';
import 'vue3-pdf-app/dist/icons/main.css'; // 引入样式

interface Props {
  src: string; // pdf地址
  width?: string | number; // 预览容器宽度
  height?: string | number; // 预览容器高度
  pageScale?: number | string; // 页面默认缩放规则，可选 'page-actual'|'page-width'|'page-height'|'page-fit'|'auto'
  theme?: string; // 预览主题 可选 dark | light
  fileName?: string; // 覆盖pdf文件名
}
let config = {
  sidebar: {
    viewThumbnail: true,
    viewOutline: true,
    viewAttachments: false,
  },
  secondaryToolbar: false,
  toolbar: {
    toolbarViewerRight: {
      presentationMode: false,
      openFile: false,
      print: true,
      download: true,
      viewBookmark: false,
    },
    toolbarViewerLeft: {
      findbar: false,
      previous: true,
      next: true,
      pageNumber: true,
    },
  }
}
const props = withDefaults(defineProps<Props>(), {
  src: '',
  width: '100%',
  height: '100vh',
  pageScale: 'page-fit', // 默认自适应展示一页
  theme: 'light',
  fileName: ''
});
const viewerWidth = computed(() => {
  if (typeof props.width === 'number') {
    return props.width + 'px';
  } else {
    return props.width;
  }
});
const viewerHeight = computed(() => {
  if (typeof props.height === 'number') {
    return props.height + 'px';
  } else {
    return props.height;
  }
});
const emit = defineEmits(['loaded', 'closePdf']);
function pagesRendered(pdfApp: any) {
  console.log('pagesRendered pdfApp:', pdfApp);
  emit('loaded', pdfApp);
}
function closePdfHandler() {
  emit('closePdf');
}
</script>
<style>
.closeBtn {
  color: #fff;
  width: 40px;
  text-align: center;
  line-height: 32px;
}
</style>