import {showSuccessToast, showToast,showDialog } from 'vant';
export class VantToast {

  static successTip(str: string) {
    showSuccessToast(str);
  }
  static showDialog(message:string,okHandler:Function) {
    showDialog({
      title: '提示',
      message: message,
      confirmButtonColor:'#C0202F'
    }).then(() => {
      okHandler();
    });
  }


}